import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DeleteTaskTimelog } from "../../../services/time";

function DeletionModal({
  item,
  setShowDeletionModal,
  renderer,
  setRenderer,
  updated,
  setUpdated,
  deleteTaskTimelog,
  deleteMilestone,
  deleteGroup,
}) {
  const dispatch = useDispatch();
  const deleteTaskCreation = dispatch.projectDetails.projectTaskDeletion;

  const handleDeleteTask = async () => {
    setShowDeletionModal(false);

    if (deleteTaskTimelog) {
      const responseData = await DeleteTaskTimelog({
        id: deleteTaskTimelog?._id,
      });
      setUpdated(!updated);
      if (responseData && responseData.status === 200) {
        toast.success(responseData.data.message);
      } else {
        toast.error(responseData.message);
      }
    } else if (deleteMilestone) {
      const responseData =
        await dispatch.projectDetails.projectMilestoneDeletion({
          project_id: deleteMilestone.project_id,
          milestone_id: deleteMilestone._id,
        });
      if (responseData) {
        toast.success(responseData.message);
        setRenderer(!renderer);
      } else {
        toast.error(responseData.message);
      }
    } else if (deleteGroup) {
      const responseData = await dispatch.projectDetails.projectGroupDeletion({
        project_id: deleteGroup.project_id,
        group_id: deleteGroup._id,
      });
      if (responseData) {
        toast.success(responseData.message);
        setRenderer(!renderer);
      } else {
        toast.error(responseData.message);
      }
    } else {
      const responseData = await deleteTaskCreation({
        project_id: item?.project_id,
        task_id: item?._id,
      });
      if (responseData && responseData.success) {
        setRenderer(!renderer);
        toast.success(responseData.task);
      } else {
        toast.error(responseData.message);
      }
    }
  };
  return (
    <div className="fixed inset-20 h-24 flex justify-center items-center z-50">
      <div
        className="p-4 pt-2 pr-2 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
        role="alert"
      >
        <div className="flex justify-end pb-2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setShowDeletionModal(false);
            }}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="flex items-center justify-center mb-3 pr-2">
          <svg
            className="flex-shrink-0 w-4 h-4 mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <h3 className="text-sm font-medium">
            Do you wan't to delete this task
          </h3>
        </div>
        <div className="flex items-center justify-center space-x-3">
          <button
            type="button"
            onClick={() => handleDeleteTask()}
            className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            YES
          </button>
          <button
            type="button"
            onClick={() => {
              setShowDeletionModal(false);
            }}
            className="text-blue-800 bg-transparent border border-blue-800 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-blue-600 dark:border-blue-600 dark:text-blue-400 dark:hover:text-white dark:focus:ring-blue-800"
            data-dismiss-target="#alert-additional-content-1"
            aria-label="Close"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeletionModal;
