import {
  trashedListsService,
  trashedDeleteService,
  trashedRestoreService,
  StatusListService,
  createStatusService,
  StatusDeleteService,
  StatusEditService,
} from "../services/projectDetails";

export const trashandStatus = {
  state: {
    task: [],
    timelogs: [],
    milestone: [],
    groups: [],
    status: [],
  },
  reducers: {
    // Corrected typo here
    getTrashedResponseData: (state, data) => {
      return {
        ...state,
        task: data.task,
        timelogs: data.timelogs,
        milestone: data.milestone,
        groups: data.groups,
      };
    },
    getStatusResponseData: (state, data) => {
      return {
        ...state,
        status: data.status,
      };
    },
  },
  effects: (dispatch) => ({
    async getTrashedData(data) {
      try {
        const responseData = await trashedListsService(data);
        this.getTrashedResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
    async deleteTrashedData(data) {
      try {
        const responseData = await trashedDeleteService(data);
        this.getTrashedResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
    async restoreTrashedData(data) {
      try {
        const responseData = await trashedRestoreService(data);
        this.getTrashedResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
    async getStatusData(data) {
      try {
        const responseData = await StatusListService(data);
        this.getStatusResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
    async createStatus(data) {
      try {
        const responseData = await createStatusService(data);
        this.getStatusResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async deleteStatusData(data) {
      try {
        const responseData = await StatusDeleteService(data);
        this.getStatusResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
    async editStatusData(data) {
      try {
        const responseData = await StatusEditService(data);
        this.getStatusResponseData(responseData.data);
        return responseData.data;
      } catch (err) {
        throw err;
      }
    },
  }),
};
