import React from "react";

const TaskPriorityConstants={
  "High": {
    bgColor: "bg-orange-100",
    textColor: "text-orange-600",
    text: "High",
    icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 0.49999C4.80982 0.49999 4.62064 0.56416 4.46647 0.693336L0.299944 4.70609C-0.0534125 5.00027 -0.101749 5.52614 0.19327 5.8795C0.487456 6.23285 1.01249 6.28036 1.36668 5.98617L5.009 2.41011L8.64422 6.00618C9.00258 6.29453 9.52761 6.23786 9.81596 5.87951C10.1043 5.52115 10.0476 4.99695 9.68929 4.70776L5.52237 0.684168C5.36986 0.56166 5.18485 0.49999 4.99983 0.49999Z" fill="#ED4901"/>
    </svg>
    ,
  },
  "highest": {
    bgColor: "bg-orange-100",
    textColor: "text-orange-600",
    text: "Highest",
    icon: <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99983 5.49999C6.80982 5.49999 6.62064 5.56416 6.46647 5.69334L2.29994 9.70609C1.94659 10.0003 1.89825 10.5261 2.19327 10.8795C2.48746 11.2329 3.01249 11.2804 3.36668 10.9862L7.009 7.41011L10.6442 11.0062C11.0026 11.2945 11.5276 11.2379 11.816 10.8795C12.1043 10.5211 12.0476 9.99695 11.6893 9.70776L7.52237 5.68417C7.36986 5.56166 7.18485 5.49999 6.99983 5.49999Z" fill="#ED4901"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99983 0.49999C6.80982 0.49999 6.62064 0.56416 6.46647 0.693336L2.29994 4.70609C1.94659 5.00027 1.89825 5.52614 2.19327 5.8795C2.48746 6.23285 3.01249 6.28036 3.36668 5.98617L7.009 2.41011L10.6442 6.00618C11.0026 6.29453 11.5276 6.23786 11.816 5.87951C12.1043 5.52115 12.0476 4.99695 11.6893 4.70776L7.52237 0.684168C7.36986 0.56166 7.18485 0.49999 6.99983 0.49999Z" fill="#ED4901"/>
    </svg>
    ,
  },
  "Medium": {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
    text: "Medium",
    icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="4.5" width="10" height="2" rx="1" fill="#FDC05E"/>
    <rect y="0.5" width="10" height="2" rx="1" fill="#FDC05E"/>
    </svg>
    ,
  },
  "Low": {
    bgColor: "bg-blue-100",
    textColor: "text-blue-600",
    text: "Low",
    icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 6.19023C4.80982 6.19023 4.62064 6.12606 4.46647 5.99688L0.299944 1.98413C-0.0534125 1.68994 -0.101749 1.16407 0.19327 0.810719C0.487456 0.457362 1.01249 0.409859 1.36668 0.704045L5.009 4.2801L8.64422 0.684036C9.00258 0.395684 9.52761 0.452354 9.81596 0.810711C10.1043 1.16907 10.0476 1.69327 9.68929 1.98245L5.52237 6.00605C5.36986 6.12856 5.18485 6.19023 4.99983 6.19023Z" fill="#1768E5"/>
    </svg>
    ,
  },
  "lowest": {
    bgColor: "bg-blue-100",
    textColor: "text-blue-600",
    text: "Lowest",
    icon: <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 6.50001C4.80982 6.50001 4.62064 6.43584 4.46647 6.30666L0.299944 2.29391C-0.0534125 1.99973 -0.101749 1.47386 0.19327 1.1205C0.487456 0.767146 1.01249 0.719643 1.36668 1.01383L5.009 4.58989L8.64422 0.99382C9.00258 0.705468 9.52761 0.762138 9.81596 1.12049C10.1043 1.47885 10.0476 2.00305 9.68929 2.29224L5.52237 6.31583C5.36986 6.43834 5.18485 6.50001 4.99983 6.50001Z" fill="#1768E5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 11.5C4.80982 11.5 4.62064 11.4358 4.46647 11.3067L0.299944 7.29391C-0.0534125 6.99973 -0.101749 6.47386 0.19327 6.1205C0.487456 5.76715 1.01249 5.71964 1.36668 6.01383L5.009 9.58989L8.64422 5.99382C9.00258 5.70547 9.52761 5.76214 9.81596 6.12049C10.1043 6.47885 10.0476 7.00305 9.68929 7.29224L5.52237 11.3158C5.36986 11.4383 5.18485 11.5 4.99983 11.5Z" fill="#1768E5"/>
    </svg>
    ,
  },
  null: {
    bgColor: "bg-gray-100",
    textColor: "text-red-600",
    text: "null",
    icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 6.19023C4.80982 6.19023 4.62064 6.12606 4.46647 5.99688L0.299944 1.98413C-0.0534125 1.68994 -0.101749 1.16407 0.19327 0.810719C0.487456 0.457362 1.01249 0.409859 1.36668 0.704045L5.009 4.2801L8.64422 0.684036C9.00258 0.395684 9.52761 0.452354 9.81596 0.810711C10.1043 1.16907 10.0476 1.69327 9.68929 1.98245L5.52237 6.00605C5.36986 6.12856 5.18485 6.19023 4.99983 6.19023Z" fill="#1768E5"/>
    </svg>
    ,
  },
}
const TaskPriority = (props) => {
  return (
    <div className={`py-0.5 pl-2 pr-2 ${TaskPriorityConstants[props.priority]?.bgColor} rounded-full`}>
      <p className={`text-xss ${TaskPriorityConstants[props.priority]?.textColor} font-semibold flex items-center gap-1`}>
        {TaskPriorityConstants[props.priority]?.icon}
        {TaskPriorityConstants[props.priority]?.text}
        
      </p>
    </div>
  );
};
export default function TaskBoardComponent(props) {
  return (
    <li className={`border rounded-md p-4 shadow-200 ${props.bgColor} mb-3 w-full`}>
      <div className="flex justify-between pb-3">
        <button className="grow text-tiny text-gray-800 font-semibold text-left" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTaskDetails" aria-controls="offcanvasRight">
          {props.taskName}
        </button>
        <svg width="12" height="17" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <rect width="2.8" height="2.8" fill="#C7CED9" />
          <rect y="5.60004" width="2.8" height="2.8" fill="#C7CED9" />
          <rect y="11.2" width="2.8" height="2.8" fill="#C7CED9" />
          <rect x="5.59811" width="2.8" height="2.8" fill="#C7CED9" />
          <rect x="5.59811" y="5.60004" width="2.8" height="2.8" fill="#C7CED9" />
          <rect x="5.59811" y="11.2" width="2.8" height="2.8" fill="#C7CED9" />
        </svg>
      </div>
      <hr />
      <div className="pt-3 flex items-center gap-3">
        <TaskPriority priority={props.priority}/>

        <div className="py-0.5 pl-2 pr-2 bg-white rounded-full border">
          <p className="text-xs text-gray-500 font-semibold flex items-center gap-1">
            <svg  width="14" height="14" viewBox="0 0 14 14" fill="none"  xmlns="http://www.w3.org/2000/svg" >
              <path d="M11.2 2.1V0.7C11.2 0.514348 11.1263 0.336301 10.995 0.205025C10.8637 0.0737498 10.6857 0 10.5 0C10.3143 0 10.1363 0.0737498 10.005 0.205025C9.87375 0.336301 9.8 0.514348 9.8 0.7V2.1H4.2V0.7C4.2 0.514348 4.12625 0.336301 3.99497 0.205025C3.8637 0.0737498 3.68565 0 3.5 0C3.31435 0 3.1363 0.0737498 3.00503 0.205025C2.87375 0.336301 2.8 0.514348 2.8 0.7V2.1H0V13.3H14V2.1H11.2ZM12.6 11.9H1.4V3.5H12.6V11.9ZM4.9 5.6H3.5V7H4.9V5.6ZM7.7 5.6H6.3V7H7.7V5.6ZM10.5 5.6H9.1V7H10.5V5.6ZM4.9 8.4H3.5V9.8H4.9V8.4ZM7.7 8.4H6.3V9.8H7.7V8.4ZM10.5 8.4H9.1V9.8H10.5V8.4Z" fill="#606C80" />
            </svg>
            Due Date 27 Sep
          </p>
        </div>
      </div>
     
    </li>
  );
}
