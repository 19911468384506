
import React, { useState, useEffect } from 'react';
import TaskDetails from './TaskDetails';
import TaskTimeLog from './TaskTimeLog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./tasks.css";
import EditTask from './EditTask';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

const statusLabelColors = {
    'Production': {
        bgColor: "bg-green-100",
        textColor: "text-green-600",
        text: "Production",
    },
    'QA': {
        bgColor: "bg-blue-100",
        textColor: "text-blue-600",
        text: "QA",
    },
    'In Progress': {
        bgColor: "bg-yellow-100",
        textColor: "text-yellow-600",
        text: "In Progress",
    },
    'Todo': {
        bgColor: "bg-gray-100",
        textColor: "text-gray-600",
        text: "Todo",
    },
};
const TaskPriorityConstants = {
    "High": {
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        text: "High",
        icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 0.49999C4.80982 0.49999 4.62064 0.56416 4.46647 0.693336L0.299944 4.70609C-0.0534125 5.00027 -0.101749 5.52614 0.19327 5.8795C0.487456 6.23285 1.01249 6.28036 1.36668 5.98617L5.009 2.41011L8.64422 6.00618C9.00258 6.29453 9.52761 6.23786 9.81596 5.87951C10.1043 5.52115 10.0476 4.99695 9.68929 4.70776L5.52237 0.684168C5.36986 0.56166 5.18485 0.49999 4.99983 0.49999Z" fill="#ED4901" />
        </svg>
        ,
    },
    "Medium": {
        bgColor: "bg-yellow-100",
        textColor: "text-yellow-600",
        text: "Medium",
        icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="4.5" width="10" height="2" rx="1" fill="#FDC05E" />
            <rect y="0.5" width="10" height="2" rx="1" fill="#FDC05E" />
        </svg>
        ,
    },
    "Low": {
        bgColor: "bg-blue-100",
        textColor: "text-blue-600",
        text: "Low",
        icon: <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99983 6.19023C4.80982 6.19023 4.62064 6.12606 4.46647 5.99688L0.299944 1.98413C-0.0534125 1.68994 -0.101749 1.16407 0.19327 0.810719C0.487456 0.457362 1.01249 0.409859 1.36668 0.704045L5.009 4.2801L8.64422 0.684036C9.00258 0.395684 9.52761 0.452354 9.81596 0.810711C10.1043 1.16907 10.0476 1.69327 9.68929 1.98245L5.52237 6.00605C5.36986 6.12856 5.18485 6.19023 4.99983 6.19023Z" fill="#1768E5" />
        </svg>
        ,
    }
}
const TaskTimeLabel = ({ data, taskUsedTime, taskEstimateTime, taskName, taskId }) => {

    const [showTimeLog, setShowTimeLog] = useState(false)

    return (
        <>
            <div className='cursor-pointer text-sm flex items-center gap-1 pt-1 pb-1 pl-2 pr-2 bg-gray-50 border border-gray-100 rounded-full'>
                <svg onClick={() => setShowTimeLog(true)} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z" fill="#606C80" />
                </svg>
                <p className="text-xs font-semibold text-gray-500">
                    {`${taskUsedTime != null ? taskUsedTime + 'h' + '/' + taskEstimateTime + 'h' : ''} `}
                </p>
                {
                    showTimeLog &&
                    <TaskTimeLog data={data} showTimeLog={showTimeLog} setShowTimeLog={setShowTimeLog} taskName={taskName} taskId={taskId} />
                }
            </div>
        </>
    )

};

const TodoLabel = (props) => {
    return (
        <div className="pt-1 pb-1 pl-2 pr-2 bg-gray-100 rounded-full">
            <p className="text-xss text-gray-600 font-semibold">{`${props.data} in todo`}</p>
        </div>
    );
};

const TaskPriority = (props) => {
    return (
        <div className={`py-0.5 pl-2 pr-2 ${TaskPriorityConstants[props?.priority]?.bgColor} rounded-full`}>
            <p className={`text-xss ${TaskPriorityConstants[props?.priority]?.textColor} font-semibold flex items-center gap-1`}>
                {TaskPriorityConstants[props?.priority]?.icon}
                {TaskPriorityConstants[props?.priority]?.text}

            </p>
        </div>
    );
};

const StatusLabel = (props) => {
    const status = statusLabelColors[props?.status];
    return (
        <div className={`pt-1 pb-1 pl-2 pr-2 ${status?.bgColor} rounded-full`}>
            <p className={`text-xss font-semibold ${status?.textColor}`}>
                {status?.text}
            </p>
        </div>
    );
};

const TimeLogFilterData = ({ data, item, taskData, setTaskData, taskTime }) => {
 
    return (
        <>
            <tr className="bg-white border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {item?.task_data?.taskname}
                </th>
                <td className="px-6 py-4">
                    {item?.user_data?.first_name}
                </td>
                <td className="px-6 py-4">
                    {item?.task_description}
                </td>
                <td className="px-6 py-4">
                    {item?.task_start_time}
                </td>
                <td className="px-6 py-4">
                    {item?.task_end_time}
                </td>
                <td className="px-6 py-4">
                    {item?.isBillable === true ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#90EE90" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF7F7F" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                    }
                </td>
                <td className="px-6 py-4">
                    {item?.isBilled === true ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#90EE90" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF7F7F" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                    }
                </td>
                <td className="px-6 py-4">
                    {item?.task_time_spent}
                </td>
            </tr>


        </>
    );
};

export default TimeLogFilterData;
