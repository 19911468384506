import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { projectTaskGroupCreationService } from "../../../services/projectDetails";
import { useDispatch } from "react-redux";

export default function GroupModal({
  data,
  setModal,
  mileStoneId,
  renderer,
  setRenderer,
}) {
  const dispatch = useDispatch();
  const [groupTitle, setGroupTitle] = useState("");
  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );

  const handleGroupCreation = async () => {
    const responseData = await projectTaskGroupCreationService({
      project_id: DetailedProjectData.project._id,
      milestone_id: mileStoneId,
      group_name: groupTitle,
    });

    if (responseData && responseData.status === 200) {
      toast.success(responseData?.data?.message);
      setRenderer(!renderer);
      setModal(false);
    } else {
      toast.error(responseData.message);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white p-4">
              <div className="m-2">
                <div className="flex justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    onClick={() => setModal(false)}
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="modal-body relative">
                  <label className="text-sm font-semibold">Group Name</label>
                  <input
                    className="p-3 w-full text-sm outline-none rounded-md border mt-1 mb-5"
                    type="text"
                    placeholder="Enter Group name"
                    onChange={(e) => setGroupTitle(e.target.value)}
                  />

                  <button
                    onClick={handleGroupCreation}
                    className="mb-4 p-3 px-5 w-full bg-primary-navyBlue text-sm text-white font-semibold rounded-md"
                  >
                    Create Group
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
