import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

export const MobileHeader = (props) => {
  const { tabTitle, projectname, setOpenMenu } = props;
  const location = useLocation();
  const [status, setStatus] = useState(location.pathname.split("/")[3]);
  return (
    <div
      className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-sm outline-none transition-transform ease-linear delay-2000 duration-1000 text-gray-700 top-0 right-0 border-none w-full z-50`}
      tabIndex="-1"
    >
      <div className=" p-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          onClick={() => setOpenMenu(false)}
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
        {/* <header className="text-2xl">
       { projectname}
      </header>
        */}
      </div>
      <div className="flex-grow p-6 pt-2 overflow-y-auto">
        <header className="flex flex-col">
          {tabTitle &&
            tabTitle.map((item) => (
              <Link
                key={item.id}
                to={`/projects/${projectname}/${item.status}`}
              >
                <button
                  className={` ${
                    item.status === status
                      ? "!font-bold"
                      : "border-transparent text-gray-500"
                  } mb-3 mr-5 font-title text-sm text-xl font-normal`}
                  onClick={() => setStatus(item.status)}
                >
                  {item.label}
                </button>
              </Link>
            ))}
        </header>
      </div>
    </div>
  );
};
