import React, { useState } from 'react'
import FilterByStatus from './FilterByStatus';
import FilterByTime from './FilterByTime';


export default function Filter(props) {

  const [status, setStatus] = useState("status");
  const { data, showTaskFilterMenu, setShowTaskFilterMenu, filteredData,
    setFilteredData, showFilterTasks, setShowFilterTasks, filterMenu,
    setFilterMenu,setTimeFilterMenu, timeFilterMenu,setTimeLogFilteredData,showTimeLogFilterData, setShowTimeLogFilterData,timeLogFilteredData } = props


  const switchTaskView = () => {
    switch (status) {
      case "status":
        return <FilterByStatus data={data} filteredData={filteredData} setFilteredData={setFilteredData} setShowTaskFilterMenu={setShowTaskFilterMenu}
          showFilterTasks={showFilterTasks} setShowFilterTasks={setShowFilterTasks} filterMenu={filterMenu} setFilterMenu={setFilterMenu} />
      case "time":
        return <FilterByTime data={data} setTimeLogFilteredData={setTimeLogFilteredData} setShowFilterTasks={setShowFilterTasks}
        showTimeLogFilterData={showTimeLogFilterData} setShowTimeLogFilterData={setShowTimeLogFilterData} setShowTaskFilterMenu={setShowTaskFilterMenu}
        setTimeFilterMenu={setTimeFilterMenu} timeFilterMenu={timeFilterMenu}timeLogFilteredData={timeLogFilteredData} />
      default:
        return null;
    }
  };

  return (
    <div className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-lg outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96 z-50`} tabIndex="-1" >
      <div className="flex items-center justify-between py-4 pr-4">
        <header className="px-6 border-b flex grow justify-between">
          <div>
            <button
              onClick={() => setStatus("status")}
              className={`
             ${status == "status"
                  ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                  : "text-gray-500 border-white border-b-2"
                }
               mr-8 font-title text-sm  pb-3 font-normal`}
            >
              Filter
            </button>
            <button
              onClick={() => setStatus("time")}
              className={`
             ${status == "time"
                  ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                  : "text-gray-500 border-white border-b-2"
                }
              mr-8 font-title text-sm  pb-3 font-normal`}
            >
              Time Entry Filter
            </button>
          </div>

          <div className="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowTaskFilterMenu(false)} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>


        </header>

      </div>
      <div className="flex-grow p-4 pt-2 overflow-y-auto">
        {switchTaskView()}
      </div>
    </div>
  )
}
