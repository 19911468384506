import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import TaskBoardComponent from "./TaskBoardComponent";
import TaskDetails from "./TaskDetails";
import { useDispatch } from "react-redux";

export default function TaskBoardLists({
  data,
  selectedMilestoneId,
}) {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedMilestoneId && data) {
        const getTasksByMilestone =
          await dispatch.projectDetails.getTasksByMilestone({
            project_id: data?.project?.id,
            milestone_id: selectedMilestoneId,
          });
        const statuses = await dispatch.trashandStatus.getStatusData();
        const groupedTasks = statuses.data.reduce((acc, status) => {
          if (!acc[status.task_status]) {
            acc[status.task_status] = [];
          }
          return acc;
        }, {});
        const taskIdsByStatus = {};
        statuses.data.forEach((item) => {
          if (!taskIdsByStatus[item.task_status]) {
            taskIdsByStatus[item.task_status] = [];
          }
          taskIdsByStatus[item.task_status].push(item._id);
        });

        const tasks = getTasksByMilestone.data;

        tasks.forEach((task) => {
          groupedTasks[task?.task_status?.task_status]?.push(task);
        });
        const updatedColumnsFromBackend = {};
        Object.entries(groupedTasks).forEach(([status, tasks]) => {
          updatedColumnsFromBackend[uuid()] = {
            name: status,
            taskId: taskIdsByStatus[status],
            items: tasks.map((task) => ({
              id: task._id,
              content: task.task_name,
              priority: task.task_priority,
            })),
          };
        });
        setColumns(updatedColumnsFromBackend);
      }
    };

    fetchData();
  }, [selectedMilestoneId]);

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const taskUpdated = columns[destination.droppableId];
      const taskUpdate = await dispatch.projectDetails.projectTaskUpdataion({
        project_id: data?.project?.id,
        task_id: result?.draggableId,
        task_status: taskUpdated.taskId,
      });
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              key={columnId}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="flex items-center py-3 w-80 px-4 rounded-t-lg bg-greyColor-100 mr-4">
                <div className="grow flex items-center gap-3">
                  <p className="text-grey-600 font-semibold text-sm">
                    {column.name}
                  </p>
                  <label className="text-gray-600 bg-gray-100 px-2 py-1 rounded-full text-xs">
                    {column.items.length}
                  </label>
                </div>
                <svg
                  width="14"
                  height="4"
                  viewBox="0 0 14 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.75 0.25C0.7875 0.25 0 1.0375 0 2C0 2.9625 0.7875 3.75 1.75 3.75C2.7125 3.75 3.5 2.9625 3.5 2C3.5 1.0375 2.7125 0.25 1.75 0.25ZM12.25 0.25C11.2875 0.25 10.5 1.0375 10.5 2C10.5 2.9625 11.2875 3.75 12.25 3.75C13.2125 3.75 14 2.9625 14 2C14 1.0375 13.2125 0.25 12.25 0.25ZM7 0.25C6.0375 0.25 5.25 1.0375 5.25 2C5.25 2.9625 6.0375 3.75 7 3.75C7.9625 3.75 8.75 2.9625 8.75 2C8.75 1.0375 7.9625 0.25 7 0.25Z"
                    fill="#C7CED9"
                  />
                </svg>
              </div>

              <div className="mr-4">
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className="w-80 p-4 rounded-b-lg"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#e3e3e3"
                            : "#F7F8FA",
                          minHeight: 100,
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      color: "white",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <TaskBoardComponent
                                      priority={item.priority}
                                      taskName={item.content}
                                      bgColor={
                                        snapshot.isDragging
                                          ? "bg-gray-100"
                                          : "bg-white"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
      <TaskDetails />
    </div>
  );
}
