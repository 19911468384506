import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { projectTaskMileStonetCreationService } from "../../../services/projectDetails";

export default function MileStoneModal({
  setModal,
  setOnRender,
  onRender,
}) {
  const dispatch = useDispatch();
  const [mileStoneTitle, setMileStoneTitle] = useState("");
  const [mileStoneDescription, setMileStoneDescription] = useState("");
  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );

  const handleMileStoneCreation = async () => {
    const responseData = await projectTaskMileStonetCreationService({
      project_id: DetailedProjectData.project._id,
      title: mileStoneTitle,
      description: mileStoneDescription,
    });

    if (responseData && responseData.status === 200) {
      toast.success(responseData?.data?.message);
      setModal(false);
      setOnRender(!onRender);
    } else {
      toast.error(responseData.message);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white p-4">
              <div className="m-2">
                <div className="flex justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    onClick={() => setModal(false)}
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="modal-body relative">
                  <label className="text-sm font-semibold">
                    Milestone Name
                  </label>
                  <input
                    className="p-3 w-full text-sm outline-none rounded-md border mt-1 mb-5"
                    type="text"
                    placeholder="Enter Milestone name"
                    onChange={(e) => setMileStoneTitle(e.target.value)}
                  />
                  <label className="text-sm font-semibold">Description</label>
                  <div className="flex items-start rounded-md border mt-1 mb-5 w-full">
                    <div className="p-3 pr-0">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3ZM2 7H13C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8C14 8.26522 13.8946 8.51957 13.7071 8.70711C13.5196 8.89464 13.2652 9 13 9H2C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7ZM2 11H8C8.26522 11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348 9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11Z"
                          fill="#98A2B2"
                        />
                      </svg>
                    </div>

                    <textarea
                      className="p-2 w-full text-sm outline-none"
                      type="text"
                      placeholder="Enter task description"
                      onChange={(e) => setMileStoneDescription(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={handleMileStoneCreation}
                    className="mb-4 p-3 px-5 w-full bg-primary-navyBlue text-sm text-white font-semibold rounded-md"
                  >
                    Create Milestone
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
