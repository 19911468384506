import { API_ROUTES } from "../config";
import { http } from "../http";

export const GetTimeEntries = async (data) => {
  try {
    const responseData = await http().get(API_ROUTES["time_entries"], {
      params: {
        page: data?.currentPage,
        limit: data?.limit,
        search: data?.search,
      },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
export const GetBookedTime = async (data) => {
  try {
    const responseData = await http().get(API_ROUTES["booked_time"], {
      params: {
        page: data?.currentPage,
        limit: data?.limit,
        search: data?.search,
        from: data?.from,
        to: data?.to,
      },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
export const AddLogTimeService = async (data) => {
  try {
    const responseData = await http().post(API_ROUTES["add_log_time"], data);
    return responseData.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const GetTaskTimeLog = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["get_task_log_time"]}?project_id=${data?.project_id}&task_id=${data?.task_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const EditTaskTimelog = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["edit-timelog"]}?timelog_id=${data.timeLog_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const DeleteTaskTimelog = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["delete-timelog"]}?timelog_id=${data?.id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
