import {
  activeMembersListService,
  createuserAvailabilityListCalenderService,
  getSessionService,
  membersListService,
  projectDescriptionChangeService,
  projectDetailService,
  projectRequirementNoteCreationService,
  projectRequirementNoteDeleteService,
  projectRequirementNoteUpdationService,
  projectRequirementCreationService,
  projectRequirementDeleteService,
  projectRequirementUpdationService,
  projectRoadmapIssueCreationService,
  projectRoadmapIssueDeleteService,
  projectRoadmapIssueUpdateService,
  projectRoadmapSprintDeleteService,
  projectRoadmapTaskCreationService,
  projectStatusChangeService,
  projectTechnologyCreationService,
  projectTechnologyDeleteService,
  projectTechnologyUpdationService,
  UpdateBasicProjectInfoService,
  userAvailabilityListCalenderService,
  DoFileUploadService,
  GetProjectDocuments,
  DownloadFile,
  DeleteFile,
  tasksListService,
  allTasksListService,
  tasksListServiceFromDate,
  tasksHistoryListService,
  timeLogListService,
  groupListService,
  tasksByMilestone,
  mileStoneListService,
  allUsersListService,
  projectTaskCommentCreationService,
  projectAddTaskCreationService,
  projectTaskUpdationService,
  projectTaskDeletionService,
  tasksListFilterService,
  tasksListTimeLogFilterService,
  projectTaskGroupService,
  projectTaskMilestoneService,
  projectGroupUpdationService,
  projectMilestoneUpdationService,
  
} from "../services/projectDetails";

export const projectDetails = {
  state: {
    getDetailedProjectData: {},
    membersList: {},
    tasksList: {},
    tasksHistoryList: {},
    mileStoneList: {},
    groupList: {},
    activeMembersList: {},
    userAvailabilityCalender: {},
    session: {},
    files: [],
    totalTasksCount: "",
    monthlyTasksCount: "",
    monthlyTasksCountFromDate: "",
  },

  reducers: {
    getDetailedProjectResponseData: (state, data) => {
      return {
        ...state,
        getDetailedProjectData: data,
      };
    },

    getMembersListData: (state, data) => {
      return {
        ...state,
        membersList: data,
      };
    },

    getMileStoneListData: (state, data) => {
      return {
        ...state,
        mileStoneList: data,
      };
    },

    getGroupListData: (state, data) => {
      return {
        ...state,
        groupList: data,
      };
    },

    getTimeLogListData: (state, data) => {
      return {
        ...state,
        groupList: data,
      };
    },

    getActiveMembersListData: (state, data) => {
      return {
        ...state,
        activeMembersList: data,
      };
    },

    getUserAvailabilityCalenderData: (state, data) => {
      return {
        ...state,
        userAvailabilityCalender: data,
      };
    },

    getSessionData: (state, data) => {
      return {
        ...state,
        session: data,
      };
    },

    getProjectDocumentData: (state, data) => {
      return {
        ...state,
        files: data.documents,
      };
    },

    getTasksListData: (state, data) => {
      return {
        ...state,
        tasksList: data,
        totalTasksCount: data?.totalCount,
        monthlyTasksCount: data?.count,
        monthlyTasksCountFromDate: data?.monthlyCount,
      };
    },

    getTasksHistoryData: (state, data) => {
      return {
        ...state,
        tasksHistoryList: data,
      };
    },
  },

  effects: (dispatch) => ({
    async getProjectDetailes(data) {
      try {
        const responsedata = await projectDetailService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
    async getProjectId(data) {
      try {
        const responsedata = await projectDetailService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data.project._id;
      } catch (err) {
        throw err;
      }
    },

    async getMembersList(data) {
      try {
        const responsedata = await membersListService(data);
        this.getMembersListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getAllUsersList(data) {
      try {
        const responsedata = await allUsersListService(data);
        this.getMembersListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getMileStoneList(data) {
      try {
        const responsedata = await mileStoneListService(data);
        this.getMileStoneListData(responsedata.data);
        return responsedata.data.data;
      } catch (err) {
        throw err;
      }
    },

    async getGroupList(data) {
      try {
        const responsedata = await groupListService(data);
        this.getGroupListData(responsedata.data.groups_data);
        return responsedata.data.groups_data;
      } catch (err) {
        throw err;
      }
    },

    async getTimeLogsList(data) {
      try {
        const responsedata = await timeLogListService(data);
        this.getTimeLogListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getActiveMembersList(data) {
      try {
        const responsedata = await activeMembersListService(data);
        this.getActiveMembersListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getUserAvailabilityCalender(data) {
      try {
        const responsedata = await userAvailabilityListCalenderService(data);
        this.getUserAvailabilityCalenderData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async createUserAvailabilityCalender(data) {
      try {
        const responsedata = await createuserAvailabilityListCalenderService(
          data
        );
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectStatusChange(data) {
      try {
        const responsedata = await projectStatusChangeService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectDescriptionChange(data) {
      try {
        const responsedata = await projectDescriptionChangeService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getSession(data) {
      try {
        const responsedata = await getSessionService(data);
        this.getSessionData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementCreation(data) {
      try {
        const responsedata = await projectRequirementCreationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementNoteCreation(data) {
      try {
        const responsedata = await projectRequirementNoteCreationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementUpdation(data) {
      try {
        const responsedata = await projectRequirementUpdationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementNoteUpdation(data) {
      try {
        const responsedata = await projectRequirementNoteUpdationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTechnologyCreation(data) {
      try {
        const responsedata = await projectTechnologyCreationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTechnologyUpdation(data) {
      try {
        const responsedata = await projectTechnologyUpdationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectAddTaskCreation(data) {
      try {
        const responsedata = await projectAddTaskCreationService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTaskUpdataion(data) {
      try {
        const responsedata = await projectTaskUpdationService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectMilestoneUpdataion(data) {
      try {
        const responsedata = await projectMilestoneUpdationService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectGroupUpdataion(data) {
      try {
        const responsedata = await projectGroupUpdationService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectMilestoneDeletion(data) {
      try {
        const responsedata = await projectTaskMilestoneService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectGroupDeletion(data) {
      try {
        const responsedata = await projectTaskGroupService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTaskDeletion(data) {
      try {
        const responsedata = await projectTaskDeletionService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTaskCommentCreation(data) {
      try {
        const responsedata = await projectTaskCommentCreationService(data);
        this.getTasksListgetTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementDelete(data) {
      try {
        const responsedata = await projectRequirementDeleteService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRequirementNoteDelete(data) {
      try {
        const responsedata = await projectRequirementNoteDeleteService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectTechnologyDelete(data) {
      try {
        const responsedata = await projectTechnologyDeleteService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRoadmapTaskcreation(data) {
      try {
        const responsedata = await projectRoadmapTaskCreationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRoadmapIssuecreation(data) {
      try {
        const responsedata = await projectRoadmapIssueCreationService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRoadmapIssuedelete(data) {
      try {
        const responsedata = await projectRoadmapIssueDeleteService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRoadmapIssueupdate(data) {
      try {
        const responsedata = await projectRoadmapIssueUpdateService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async projectRoadmapSprintDelete(data) {
      try {
        const responsedata = await projectRoadmapSprintDeleteService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async UpdateBasicProjectInfo(data) {
      try {
        const responsedata = await UpdateBasicProjectInfoService(data);
        this.getDetailedProjectResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async updateProjectDetailsData(data) {
      try {
        this.getDetailedProjectResponseData(data);
      } catch (err) {
        throw err;
      }
    },

    async doFileUpload(data) {
      try {
        const responsedata = await DoFileUploadService(data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getProjectDocuments(data) {
      try {
        const responsedata = await GetProjectDocuments(data);
        this.getProjectDocumentData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async downloadFile(data) {
      try {
        const responsedata = await DownloadFile(data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async deleteFile(data) {
      try {
        const responsedata = await DeleteFile(data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getTasksList(data) {
      try {
        const responsedata = await tasksListService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getTasksByMilestone(data) {
      try {
        const responsedata = await tasksByMilestone(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getAllTasksList(data) {
      try {
        const responsedata = await allTasksListService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getTasksListFromDate(data) {
      try {
        const responsedata = await tasksListServiceFromDate(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
    async getTasksListFilter(data) {
      try {
        const responsedata = await tasksListFilterService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
    async getTasksListTimeLogFilter(data) {
      try {
        const responsedata = await tasksListTimeLogFilterService(data);
        this.getTasksListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getTaskHistory(data) {
      try {
        const responsedata = await tasksHistoryListService(data);
        this.getTasksHistoryData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
  }),
};
