import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const bgColors = [
  "bg-gray-200",
  "bg-yellow-200",
  "bg-green-100",
  "bg-blue-100",
  "bg-gray-300",
  "bg-yellow-300",
  "bg-green-300",
  "bg-blue-200",
  "bg-gray-400",
  "bg-yellow-500",
  "bg-green-400",
  "bg-blue-300",
];

const textColor = [
  "gray-600",
  "yellow-600",
  "green-600",
  "blue-600",
  "black",
  "yellow-300",
  "gray-400",
  "blue-300",
];

function EditStatus({ setIsEdit, curretStatus, setCurrentStatus }) {
  const dispatch = useDispatch();
  const [selectedBgColor, setSelectedBgColor] = useState(null);
  const [selectedTextColor, setSelectedTextColor] = useState(null);
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    if (Object.keys(curretStatus).length !== 0) {
      setSelectedName(curretStatus.task_status);
      setSelectedBgColor(curretStatus.bg_color);
      setSelectedTextColor(curretStatus.text_color);
    }
  }, [curretStatus]);

  const onDeleteClick = async () => {
      const response = await dispatch.trashandStatus.deleteStatusData({
        statusId: curretStatus._id,
      });
      if (response.success) {
        toast.success(response.task);
        setIsEdit(false);
        setCurrentStatus({});
      }
  };

  const onSubmit = async () => {
    if (Object.keys(curretStatus).length === 0) {
      if (
        selectedName !== "" &&
        selectedBgColor !== null &&
        selectedTextColor !== null
      ) {
        const response = await dispatch.trashandStatus.createStatus({
          task_status: selectedName,
          text_color: selectedTextColor,
          bg_color: selectedBgColor,
        });
        if (response.status) {
          toast.success(response.message);
          setIsEdit(false);
          setCurrentStatus({});
        }
      } else {
        toast.error("Please fill in all fields.");
      }
    } else {
      const response = await dispatch.trashandStatus.editStatusData({
        statusId: curretStatus._id,
        task_status: selectedName,
        text_color: selectedTextColor,
        bg_color: selectedBgColor,
      });
      if (response?.success) {
        toast.success(response.message);
        setIsEdit(false);
        setCurrentStatus({});
      }
    }
  };

  return (
    <div className="p-4">
      <div className="flex text-black gap-2 text-sm font-medium items-center justify-start ">
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer"
          onClick={() => {
            setIsEdit(false);
            setCurrentStatus({});
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 7.00021C15.5 7.24885 15.4012 7.48731 15.2254 7.66312C15.0496 7.83894 14.8111 7.93771 14.5625 7.93771H3.70059L7.72622 11.9615C7.81339 12.0486 7.88253 12.1521 7.9297 12.266C7.97687 12.3799 8.00115 12.5019 8.00115 12.6252C8.00115 12.7485 7.97687 12.8705 7.9297 12.9844C7.88253 13.0983 7.81339 13.2018 7.72622 13.289C7.63905 13.3761 7.53558 13.4453 7.42169 13.4924C7.3078 13.5396 7.18574 13.5639 7.06247 13.5639C6.9392 13.5639 6.81714 13.5396 6.70325 13.4924C6.58936 13.4453 6.48588 13.3761 6.39872 13.289L0.773717 7.66396C0.686411 7.57687 0.617143 7.47342 0.569881 7.35952C0.522618 7.24562 0.498291 7.12352 0.498291 7.00021C0.498291 6.8769 0.522618 6.75479 0.569881 6.6409C0.617143 6.527 0.686411 6.42354 0.773717 6.33646L6.39872 0.711458C6.57476 0.53542 6.81351 0.436523 7.06247 0.436523C7.31142 0.436523 7.55018 0.53542 7.72622 0.711458C7.90226 0.887495 8.00115 1.12625 8.00115 1.37521C8.00115 1.62416 7.90226 1.86292 7.72622 2.03896L3.70059 6.06271H14.5625C14.8111 6.06271 15.0496 6.16148 15.2254 6.3373C15.4012 6.51311 15.5 6.75157 15.5 7.00021Z"
            fill="#606060"
          />
        </svg>
        Edit Status
      </div>
      <div className="py-6">
        <h2 className="text-black text-sm font-medium pb-3">Title</h2>
        <input
          type="text"
          className="border-2 pl-3 pr-14 py-2 rounded"
          defaultValue={curretStatus ? curretStatus.task_status : null}
          placeholder="Enter Name.."
          onChange={(e) => setSelectedName(e.target.value)}
        />
      </div>
      <div className="">
        <h2 className="text-black text-sm font-medium pb-4">
          Select Background color
        </h2>
        <div className="grid grid-cols-4 gap-4">
          {bgColors.map((color, index) => (
            <label
              key={index}
              className={`relative bg-opacity-50 cursor-pointer ${color} w-16 h-9 rounded-full`}
              onClick={() => setSelectedBgColor(color)}
            >
              {selectedBgColor === color && (
                <svg
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-[35%] bottom-[30%]"
                >
                  <path
                    d="M17.1394 0.803657C17.4879 0.485143 17.9517 0.309056 18.4328 0.312551C18.914 0.316046 19.3749 0.498851 19.7184 0.822391C20.0618 1.14593 20.2609 1.5849 20.2736 2.04667C20.2863 2.50844 20.1117 2.9569 19.7865 3.29741L9.91546 15.1487C9.74572 15.3242 9.54086 15.465 9.31313 15.5628C9.08539 15.6605 8.83946 15.7132 8.59004 15.7177C8.34061 15.7221 8.09282 15.6782 7.86147 15.5886C7.63012 15.499 7.41997 15.3655 7.24358 15.1962L0.697488 8.91191C0.51519 8.74884 0.368974 8.55219 0.267561 8.33369C0.166149 8.11519 0.111618 7.87932 0.107222 7.64015C0.102827 7.40098 0.148656 7.16341 0.241977 6.94161C0.335297 6.71982 0.474196 6.51834 0.650388 6.34919C0.826581 6.18005 1.03646 6.0467 1.26749 5.95712C1.49853 5.86753 1.746 5.82353 1.99514 5.82775C2.24427 5.83197 2.48997 5.88432 2.71757 5.98168C2.94517 6.07903 3.15002 6.2194 3.31988 6.39441L8.50035 11.3653L17.0924 0.855908L17.1394 0.803657Z"
                    fill="#848484"
                  />
                </svg>
              )}
            </label>
          ))}
        </div>
      </div>
      <div className="py-6">
        <h2 className="text-black text-sm font-medium pb-4">Select color</h2>
        <div className="grid grid-cols-4 gap-4">
          {textColor.map((color, index) => (
            <label
              key={index}
              className={`relative cursor-pointer bg-${color} w-16 h-9 rounded-full`}
              onClick={() => setSelectedTextColor(color)}
            >
              {selectedTextColor === color && (
                <svg
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-[35%] bottom-[25%]"
                >
                  <path
                    d="M17.1394 0.803657C17.4879 0.485143 17.9517 0.309056 18.4328 0.312551C18.914 0.316046 19.3749 0.498851 19.7184 0.822391C20.0618 1.14593 20.2609 1.5849 20.2736 2.04667C20.2863 2.50844 20.1117 2.9569 19.7865 3.29741L9.91546 15.1487C9.74572 15.3242 9.54086 15.465 9.31313 15.5628C9.08539 15.6605 8.83946 15.7132 8.59004 15.7177C8.34061 15.7221 8.09282 15.6782 7.86147 15.5886C7.63012 15.499 7.41997 15.3655 7.24358 15.1962L0.697488 8.91191C0.51519 8.74884 0.368974 8.55219 0.267561 8.33369C0.166149 8.11519 0.111618 7.87932 0.107222 7.64015C0.102827 7.40098 0.148656 7.16341 0.241977 6.94161C0.335297 6.71982 0.474196 6.51834 0.650388 6.34919C0.826581 6.18005 1.03646 6.0467 1.26749 5.95712C1.49853 5.86753 1.746 5.82353 1.99514 5.82775C2.24427 5.83197 2.48997 5.88432 2.71757 5.98168C2.94517 6.07903 3.15002 6.2194 3.31988 6.39441L8.50035 11.3653L17.0924 0.855908L17.1394 0.803657Z"
                    fill="white"
                  />
                </svg>
              )}
            </label>
          ))}
        </div>
        <div className="py-12 flex items-center justify-between">
          <button
            onClick={onSubmit}
            className="bg-[#1768E5] px-5 py-2 flex justify-center items-center rounded-lg text-white gap-2 cursor-pointer hover:bg-blue-700"
          >
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
              disabled={selectedName === ""}
            >
              <path
                d="M11.1407 0.454736C11.3608 0.253569 11.6537 0.142356 11.9576 0.144563C12.2615 0.146771 12.5526 0.262226 12.7695 0.466567C12.9864 0.670908 13.1121 0.948152 13.1202 1.2398C13.1282 1.53144 13.0179 1.81468 12.8125 2.02974L6.57816 9.51474C6.47096 9.62558 6.34157 9.71454 6.19774 9.77629C6.05391 9.83803 5.89858 9.8713 5.74105 9.8741C5.58352 9.8769 5.42702 9.84918 5.2809 9.79259C5.13479 9.73599 5.00206 9.6517 4.89066 9.54474L0.756283 5.57574C0.641147 5.47274 0.5488 5.34854 0.48475 5.21054C0.4207 5.07254 0.386259 4.92357 0.383483 4.77252C0.380707 4.62147 0.409652 4.47142 0.468591 4.33134C0.52753 4.19126 0.615256 4.06401 0.726535 3.95718C0.837815 3.85035 0.970367 3.76613 1.11629 3.70955C1.26221 3.65297 1.4185 3.62518 1.57585 3.62785C1.7332 3.63051 1.88837 3.66358 2.03212 3.72506C2.17587 3.78655 2.30525 3.87521 2.41253 3.98574L5.68441 7.12524L11.111 0.487736L11.1407 0.454736Z"
                fill="white"
              />
            </svg>
            Done
          </button>
          {Object.keys(curretStatus).length !== 0 && (
            <button
              className="flex px-3 py-2 items-center justify-center gap-2 border border-[#DC3545] rounded-lg cursor-pointer hover:bg-gray-300"
              onClick={onDeleteClick}
            >
              <svg
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.30376 0.949414L3.12782 1.3125H0.781955C0.349436 1.3125 0 1.67559 0 2.125C0 2.57441 0.349436 2.9375 0.781955 2.9375H10.1654C10.5979 2.9375 10.9474 2.57441 10.9474 2.125C10.9474 1.67559 10.5979 1.3125 10.1654 1.3125H7.81955L7.64361 0.949414C7.51166 0.672656 7.24042 0.5 6.94474 0.5H4.00263C3.70696 0.5 3.43571 0.672656 3.30376 0.949414ZM10.1654 3.75H0.781955L1.3 12.3574C1.3391 12.9998 1.85226 13.5 2.47049 13.5H8.47688C9.09511 13.5 9.60827 12.9998 9.64737 12.3574L10.1654 3.75Z"
                  fill="#686868"
                />
              </svg>
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditStatus;
