import React from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
// import QuillEditor from "react-quill";
// import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import TimeLogs from "./TimeLogs";
import TaskComments from "./Comments/TaskComments";

const TaskPriorityConstants = {
  High: {
    bgColor: "bg-orange-100",
    textColor: "text-orange-600",
    text: "High",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99983 0.49999C4.80982 0.49999 4.62064 0.56416 4.46647 0.693336L0.299944 4.70609C-0.0534125 5.00027 -0.101749 5.52614 0.19327 5.8795C0.487456 6.23285 1.01249 6.28036 1.36668 5.98617L5.009 2.41011L8.64422 6.00618C9.00258 6.29453 9.52761 6.23786 9.81596 5.87951C10.1043 5.52115 10.0476 4.99695 9.68929 4.70776L5.52237 0.684168C5.36986 0.56166 5.18485 0.49999 4.99983 0.49999Z"
          fill="#ED4901"
        />
      </svg>
    ),
  },
  Medium: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
    text: "Medium",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="4.5" width="10" height="2" rx="1" fill="#FDC05E" />
        <rect y="0.5" width="10" height="2" rx="1" fill="#FDC05E" />
      </svg>
    ),
  },
  Low: {
    bgColor: "bg-blue-100",
    textColor: "text-blue-600",
    text: "Low",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99983 6.19023C4.80982 6.19023 4.62064 6.12606 4.46647 5.99688L0.299944 1.98413C-0.0534125 1.68994 -0.101749 1.16407 0.19327 0.810719C0.487456 0.457362 1.01249 0.409859 1.36668 0.704045L5.009 4.2801L8.64422 0.684036C9.00258 0.395684 9.52761 0.452354 9.81596 0.810711C10.1043 1.16907 10.0476 1.69327 9.68929 1.98245L5.52237 6.00605C5.36986 6.12856 5.18485 6.19023 4.99983 6.19023Z"
          fill="#1768E5"
        />
      </svg>
    ),
  },
};

const statusLabelColors = {
  Production: {
    bgColor: "bg-green-100",
    textColor: "text-green-600",
    text: "Production",
  },
  QA: {
    bgColor: "bg-blue-100",
    textColor: "text-blue-600",
    text: "QA",
  },
  "In Progress": {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
    text: "In Progress",
  },
  Todo: {
    bgColor: "bg-gray-100",
    textColor: "text-gray-600",
    text: "Todo",
  },
};

const TaskPriority = (props) => {
  return (
    <div
      className={`py-0.5 pl-2 pr-2 ${
        TaskPriorityConstants[props?.priority]?.bgColor
      } rounded-full`}
    >
      <p
        className={`text-xss ${
          TaskPriorityConstants[props?.priority]?.textColor
        } font-semibold flex items-center gap-1`}
      >
        {TaskPriorityConstants[props?.priority]?.icon}
        {TaskPriorityConstants[props?.priority]?.text}
      </p>
    </div>
  );
};

const StatusLabel = (props) => {
  const status = statusLabelColors[props.status];
  return (
    <div className={`pt-1 pb-1 pl-2 pr-2 ${status?.bgColor} rounded-full`}>
      <p className={`text-xss font-semibold ${status?.textColor}`}>
        {status?.text}
      </p>
    </div>
  );
};

const TaskTimeLabel = (props) => {
  return props.taskTime ? (
    <div className="text-sm flex items-center gap-1 pt-1 pb-1 pl-2 pr-2 bg-gray-50 border border-gray-100 rounded-full">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z"
          fill="#606C80"
        />
      </svg>

      <p className="text-xs font-semibold text-gray-500">{`${props.taskTime}h logged`}</p>
    </div>
  ) : (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z"
        fill="#606C80"
      />
    </svg>
  );
};

function TaskDetails({
  task,
  isTaskDetailsShown,
  setIsTaskDetailsShown,
  data,
}) {
  const usersData = useSelector((state) => state.auth.userInfoData);
  const sanitizedContent = DOMPurify.sanitize(task?.task_description);

  return (
    <>
      {isTaskDetailsShown ? (
        <div
          className="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white z-50 bg-clip-padding shadow-lg outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-q pl-2"
          tabIndex="-1"
          id="offcanvasTaskDetails"
          aria-labelledby="offcanvasTaskDetailsLabel"
        >
          <div className="offcanvas-header flex items-center justify-between p-4 pb-1">
            <h5
              className="offcanvas-title mb-0 leading-normal font-semibold text-lg text-black"
              id="offcanvasTaskDetailsLabel"
            >
              {task.task_name}
            </h5>
            {/* <button
                type="button"
                onClick={() => setIsTaskDetailsShown(false)}
                className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >X</button> */}
            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer w-6 h-6"
                onClick={() => setIsTaskDetailsShown(false)}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="offcanvas-body flex-grow p-4 overflow-y-auto pt-0">
            {/* {/ <p className="text-xs text-gray-400 mb-4">Yesterday 2:00 PM</p> /}
              {/ <p className="text-tiny text-gray-500 mb-4"> {task.task_description} </p> /} */}
            <div
              className="text-sm font-normal py-5"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
            {/* <QuillEditor
        
              theme="snow"
              value={task.task_description}
        // onChange={(value) => setValue(value)}
      /> */}
            <div className="flex items-center gap-2 mb-4">
              <p className="text-xs font-medium opacity-65 pr-2">
                {moment(task?.createdAt).format("MMM D,YYYY h:mm A")}
              </p>
              <StatusLabel status={task.task_status} />
              <TaskPriority priority={task.task_priority} />
              <TaskTimeLabel taskTime="49" />
            </div>
            <div className="flex items-center justify-between gap-2 mb-4">
              <div className="flex items-center gap-1">
                <p className="text-xs text-gray-400 font-medium mr-3">
                  Assigned to:
                </p>
                <div className="text-tiny">
                  <div className="text-white text-[10px] flex items-center justify-center bg-primary-navyBlue border border-primary-navyBlue w-6 h-6 min-w-[24px] rounded-full cursor-pointer uppercase">
                    {task?.assigned_by?.first_name &&
                      task?.assigned_by?.first_name.substring(0, 1)}
                    {task?.assigned_by?.last_name
                      ? task?.assigned_by?.last_name?.substring(0, 1)
                      : task?.assigned_by?.first_name?.substring(1, 2)}
                  </div>
                </div>
                <p className="text-tiny text-gray-500 font-semibold">{`${task?.assigned_by?.first_name} ${task?.assigned_by?.last_name}`}</p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex flex-col md:flex-row items-center gap-2">
                  <div className="py-0.5 pl-2 pr-2">
                    <p className="text-xs text-gray-500 font-semibold flex items-center gap-1">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2 2.1V0.7C11.2 0.514348 11.1263 0.336301 10.995 0.205025C10.8637 0.0737498 10.6857 0 10.5 0C10.3143 0 10.1363 0.0737498 10.005 0.205025C9.87375 0.336301 9.8 0.514348 9.8 0.7V2.1H4.2V0.7C4.2 0.514348 4.12625 0.336301 3.99497 0.205025C3.8637 0.0737498 3.68565 0 3.5 0C3.31435 0 3.1363 0.0737498 3.00503 0.205025C2.87375 0.336301 2.8 0.514348 2.8 0.7V2.1H0V13.3H14V2.1H11.2ZM12.6 11.9H1.4V3.5H12.6V11.9ZM4.9 5.6H3.5V7H4.9V5.6ZM7.7 5.6H6.3V7H7.7V5.6ZM10.5 5.6H9.1V7H10.5V5.6ZM4.9 8.4H3.5V9.8H4.9V8.4ZM7.7 8.4H6.3V9.8H7.7V8.4ZM10.5 8.4H9.1V9.8H10.5V8.4Z"
                          fill="#606C80"
                        />
                      </svg>
                      Start date:{" "}
                      {moment(task?.task_start_date).format("MMM D, YYYY")}
                    </p>
                  </div>
                  <div className="py-0.5 pl-2 pr-2">
                    <p className="text-xs text-gray-500 font-semibold flex items-center gap-1">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2 2.1V0.7C11.2 0.514348 11.1263 0.336301 10.995 0.205025C10.8637 0.0737498 10.6857 0 10.5 0C10.3143 0 10.1363 0.0737498 10.005 0.205025C9.87375 0.336301 9.8 0.514348 9.8 0.7V2.1H4.2V0.7C4.2 0.514348 4.12625 0.336301 3.99497 0.205025C3.8637 0.0737498 3.68565 0 3.5 0C3.31435 0 3.1363 0.0737498 3.00503 0.205025C2.87375 0.336301 2.8 0.514348 2.8 0.7V2.1H0V13.3H14V2.1H11.2ZM12.6 11.9H1.4V3.5H12.6V11.9ZM4.9 5.6H3.5V7H4.9V5.6ZM7.7 5.6H6.3V7H7.7V5.6ZM10.5 5.6H9.1V7H10.5V5.6ZM4.9 8.4H3.5V9.8H4.9V8.4ZM7.7 8.4H6.3V9.8H7.7V8.4ZM10.5 8.4H9.1V9.8H10.5V8.4Z"
                          fill="#606C80"
                        />
                      </svg>
                      Due date:{" "}
                      {moment(task?.task_end_date).format("MMM D, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="my-8 border-[0.5px]" />
            <div className="flex flex-col gap-3 pt-5">
              <TimeLogs data={data} task={task} />
              <TaskComments usersData={usersData} task={task} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TaskDetails;
