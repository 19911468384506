import { getUserBookings, createUserBookings, listAllMembersOnlyService, listAllMembersService, listPeopleService, memberAvailabilityCalenderService, memberavailabilityStatisticsService, memberavailabilityStatisticsServiceById, memberUpdateService, } from "../services/people";

export const people = {
    state: {
        people: [],
    },
    reducers: {
        listPeople: (state, data) => {
            return {
                ...state,
                people: data,
                peopleTotal: data.totalcount,
                peopleLimit: data.limit,
            };
        },

    },
    effects: (dispatch) => ({
        async getUserBookings(data) {
            try {
                const responsedata = await getUserBookings(data);
                this.listPeople(responsedata.data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async createUserBookings(data) {
            try {
                const responsedata = await createUserBookings(data);
                this.listPeople(responsedata.data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async listPeopleData(data) {
            try {
                const responsedata = await listPeopleService(data);
                this.listPeople(responsedata.data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async listAllMembers(data) {
            try {
                const responsedata = await listAllMembersService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async listAllMembersOnly(data) {
            try {
                const responsedata = await listAllMembersOnlyService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async memberAvailabilityCalender(data) {
            try {
                const responsedata = await memberAvailabilityCalenderService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async memberavailabilityStatistics(data) {
            try {
                const responsedata = await memberavailabilityStatisticsService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;
            }
        },
        async memberavailabilityStatisticsById(data) {
            try {
                const responsedata = await memberavailabilityStatisticsServiceById(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;
            }
        },
        async memberUpdate(data) {
            try {
                const responsedata = await memberUpdateService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;
            }
        },

    }),
}