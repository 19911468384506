
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import Loader from '../Utilities/Loader';

const ChangePassword = ({ userInfo, changePassword }) => {
    const [isUpdateLoading, setUpdateLoading] = useState(false);

    const handleResetPassword = async (values) => {
        try {
            setUpdateLoading(true);

            const responseData = await changePassword({
                password: values.confirmPassword,
            });

            if (responseData.success) {
                toast.success(responseData.message);
            } else {
                toast.error(responseData.message);
            }
            setUpdateLoading(false);

        } catch (err) {
            toast.error(err.error);
            setUpdateLoading(false);

        }

    }

    return (
        <div className="mt-5 pt-6">
            {/* Reset password section */}
            <div className="flex flex-wrap items-center justify-between pb-6">
                <h3 className="font-title text-xl font-medium text-black">Change Password</h3>
            </div>
            <div className="flex flex-col items-center justify-center w-full">

                <Formik
                    initialValues={{
                        newPassword: "",
                        confirmPassword: ""
                    }}
                    validateOnChange={false}
                    validationSchema={Yup.object().shape({
                        newPassword: Yup.string().required("New password is required"),
                        confirmPassword: Yup.string().required("Confirm password is required").when("newPassword", {
                            is: val => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                                [Yup.ref("newPassword")],
                                "Both password need to be the same"
                            )
                        })
                    })}
                    onSubmit={(values) => handleResetPassword(values)}
                >
                    {({ errors }) => (
                        <Form className="flex flex-col justify-center  w-full">
                            <div className="relative">
                                <Field className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4 w-full mb-5 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                    name="newPassword"
                                    type="password"
                                    placeholder='New password' />
                                <span className="text-red-500 block text-xs absolute left-0 bottom-0.5 font-title">
                                    {errors.newPassword}
                                </span>
                            </div>
                            <div className="relative">
                                <Field className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4 w-full mb-5 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                    type="password"
                                    name="confirmPassword"
                                    placeholder='Confirm password' />
                                <span className="text-red-500 text-xs absolute left-0 bottom-0.5 font-title">
                                    {errors.confirmPassword}
                                </span>
                            </div>
                            <button type="submit" className="btn-primary mb-6 w-full p-4 bg-primary-navyBlue hover:bg-opacity-75 text-white rounded-md text-sm font-semibold font-title"
                            >Update Password
                                {isUpdateLoading ? <Loader /> : null}

                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}


const mapState = (state) => ({
    userInfo: state.auth.userInfo,
});

const mapDispatch = (dispatch) => ({
    changePassword: dispatch.auth.changePassword,
});

export default connect(mapState, mapDispatch)(ChangePassword)