import { API_ROUTES } from "../config";
import { http } from "../http";

export const GetFirstMilestone = async () => {
  try {
    const responseData = await http().get(`${API_ROUTES["get-first-milestone"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const GetOneMilestone = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["get-one-milestone"]}?milestone_id=${data?.milestone_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};


export const ListTaskAndMilestones = async () => {
  try {
    const responseData = await http().get(`${API_ROUTES["list-task-and-milestones"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const StatusEditService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["edit-status"]}?status_id=${data.statusId}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const StatusDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["delete-status"]}?status_id=${data.statusId}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const createStatusService = async (data) => {
  try {
    const responseData = await http().post(API_ROUTES["create-status"], data);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const StatusListService = async (data) => {
  try {
    const responseData = await http().get(`${API_ROUTES["list-status"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const trashedRestoreService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["restore-trashed-task"]}?task_id=${data.trashId}&project_id=${data.project_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const trashedDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["permanent-delete-trashedtasks"]}?task_id=${data.trashId}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const trashedListsService = async (data) => {
  try {
    const responseData = await http().get(`${API_ROUTES["list-all-trash"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectDetailService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["project-detail"]}${data?.projectName}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const membersListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["all-invite-user"]}?project_id=${data?.project_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const allUsersListService = async (data) => {
  try {
    const responseData = await http().get(`${API_ROUTES["all-users"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const mileStoneListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["list-milestone"]}?project_id=${data?.project_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
export const groupListService = async (data) => {
  try {
    // const responseData = await http().get(`${API_ROUTES["list-group"]}?project_id=${data?.project_id}`);
    const responseData = await http().get(`${API_ROUTES["list-group"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
export const timeLogListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["list-timelogs"]}?project_id=${data?.project_id}`
    );
    return responseData.data;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const activeMembersListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["invite-user"]}?project_id=${data?.project_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const userAvailabilityListCalenderService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["user-availability-calender"]}/${data?.user_id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const createuserAvailabilityListCalenderService = async (data) => {
  try {
    const responseData = await http().post(
      API_ROUTES["user-availability-calender"],
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectStatusChangeService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-status-change"]}${data?.project_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectDescriptionChangeService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-description-change"]}${data?.project_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const getSessionService = async (data) => {
  try {
    const responseData = await http().get(`${API_ROUTES["session"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/requirement`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementNoteCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/${data?.requirement_id}/note`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementUpdationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/${data?.requirement_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementNoteUpdationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/${data?.requirement_id}/${data?.note_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTechnologyCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-technology-update"]}${data?.project_id}/${data?.platform}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTechnologyUpdationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["project-technology-update"]}${data?.project_id}/${data?.platform}/${data?.tech_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRoadmapTaskCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-roadmap"]}${data?.project_id}/`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRoadmapSprintDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-roadmap"]}${data?.project_id}/${data?.roadmapID}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRoadmapIssueCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-roadmap"]}${data?.project_id}/${data?.roadmapID}/subRoad`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRoadmapIssueUpdateService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["project-roadmap"]}${data?.project_id}/${data?.roadmapID}/${data?.subRoadID}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRoadmapIssueDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-roadmap"]}${data?.project_id}/${data?.roadmapID}/${data?.subRoadID}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTechnologyDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-technology-update"]}${data?.project_id}/${data?.platform}/${data?.tech_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/${data?.requirement_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectRequirementNoteDeleteService = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-requirement"]}${data?.project_id}/${data?.requirement_id}/${data?.note_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const UpdateBasicProjectInfoService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["project-create"]}/${data.project_id}`,
      data
    );
    return responseData;
  } catch (err) {
    // console.log('err', err);
    throw err;
  }
};

export const DoFileUploadService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["project-files-upload"]}`,
      data
    );
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const GetProjectDocuments = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["project-files-get"]}/${data.projectId}`
    );
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const DownloadFile = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["project-files-download"]}/${data.docId}`,
      {
        responseType: "blob",
      }
    );
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const DeleteFile = async (data) => {
  try {
    const responseData = await http().delete(
      `${API_ROUTES["project-files-delete"]}/${data.docId}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

//to get all tasks for corresponding project
export const tasksListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["list-tasks"]}?project_id=${data?.project_id}&page=${data?.page}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

//to get all the tasks
export const allTasksListService = async (data) => {
  try {
    const responseData = await http().get(`${API_ROUTES["list-tasks"]}`);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

// to get the list of task accroding to the date (monthwise)
export const tasksListServiceFromDate = async (data) => {
  try {
    const responseData = await http().get(API_ROUTES["list-tasks"], {
      params: {
        page: data?.currentPage,
        limit: data?.limit,
        search: data?.search,
        from_date: data?.from,
        to_date: data?.to,
      },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

// to get the list of task accroding to the date (monthwise)
export const tasksListFilterService = async (data) => {
  try {
    const responseData = await http().get(API_ROUTES["list-tasks"], {
      params: {
        milestone_id: data?.milestone_id,
        task_status: data?.task_status,
        assigned_to: data?.assigned_to,
        is_task_finished: data?.is_task_finished,
        task_due_date: data?.due_date,
      },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const tasksListTimeLogFilterService = async (data) => {
  try {
    const responseData = await http().get(API_ROUTES["get-logtime"], {
      params: {
        start_date: data?.start_date,
        end_date: data?.end_date,
        user_id: data?.user_id,
      },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

//to get the history of tasks
export const tasksHistoryListService = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["list-task-history"]}?tw_task_id=${data?.taskId}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskGroupCreationService = async (data) => {
  try {
    const responseData = await http().post(`${API_ROUTES["add-group"]}`, data);
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskUpdationService = async (data) => {
  try {
    const responseData = await http().put(`${API_ROUTES["edit-tasks"]}`, data, {
      params: { task_id: data.task_id, project_id: data.project_id },
    });
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectMilestoneUpdationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["edit-milestone"]}`,
      data,
      {
        params: {
          milestone_id: data.milestone_id,
          project_id: data.project_id,
        },
      }
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectGroupUpdationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["edit-groups"]}`,
      data,
      { params: { group_id: data.group_id, project_id: data.project_id } }
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskMilestoneService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["soft-del-milestone"]}?milestone_id=${data?.milestone_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskGroupService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["soft-delete-groups"]}?group_id=${data?.group_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskDeletionService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["delete-tasks"]}?task_id=${data?.task_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

//add tasks
export const projectAddTaskCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["create-tasks"]}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskCommentCreationService = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["add-comment"]}`,
      data,
      {
        params: {
          task_id: data.task_id,
          project_id: data.project_id,
          comment_id: data.comment_id,
        },
      }
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const projectTaskMileStonetCreationService = async (data) => {
  try {
    const responseData = await http().post(
      `${API_ROUTES["add-milestone"]}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

//to get all tasks by milestone
export const tasksByMilestone = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["tasks-by-milestone"]}?project_id=${data?.project_id}&page=${data?.page}&milestone_id=${data?.milestone_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
