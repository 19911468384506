import {
  ListTaskAndMilestones,
  GetFirstMilestone,
  GetOneMilestone,
} from "../services/projectDetails";

export const newProjectDetails = {
  state: {
    tasksList: {},
    mileStoneList: {},
  },

  reducers: {
    getListTaskAndMilestonesResponseData: (state, data) => {
      return {
        ...state,
        tasksList: data,
      };
    },

    getMileStoneListData: (state, data) => {
      return {
        ...state,
        mileStoneList: data,
      };
    },
  },

  effects: (dispatch) => ({
    async getGetFirstMilestone(data) {
      try {
        const responsedata = await GetFirstMilestone(data);
        this.getMileStoneListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
    async getGetOneMilestone(data) {
      try {
        const responsedata = await GetOneMilestone(data);
        this.getMileStoneListData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },

    async getListTaskAndMilestones(data) {
      try {
        const responsedata = await ListTaskAndMilestones(data);
        this.getListTaskAndMilestonesResponseData(responsedata.data);
        return responsedata.data;
      } catch (err) {
        throw err;
      }
    },
  }),
};
