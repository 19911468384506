import { API_ROUTES } from "../config";
import { http } from "../http"


export const getUserBookings = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["user-bookings"]}?start_date=${data?.start_date}&end_date=${data?.end_date}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const createUserBookings = async (data) => {
    try {
        const responseData = await http().post(API_ROUTES["user-bookings"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const listPeopleService = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["members"], { params: { page: data?.currentPage, limit: data?.limit, search: data?.search } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const listAllMembersService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["all-members"]}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}
export const listAllMembersOnlyService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["all-members-only"]}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const memberAvailabilityCalenderService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["member-availability-calender"]}`, { params: { start_date: data?.start_date, end_date: data?.end_date, designation: data?.designation, skills: data?.skills } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const memberavailabilityStatisticsService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["member-availability-statistics"]}?start_date=${data?.start_date}&end_date=${data?.end_date}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}
export const memberavailabilityStatisticsServiceById = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["member-availability-statistics-user_id"]}/${data?.nameId}?start_date=${data?.start_date}&end_date=${data?.end_date}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}



export const memberUpdateService = async (data) => {
    try {
        const responseData = await http().put(`${API_ROUTES["register"]}/${data.user_id}`, data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err

    }
}



