import React, { useState, useEffect } from "react";
import moment from "moment";
import { TaskTimeLabel } from "./TasksListComponent";
import { useDispatch } from "react-redux";

const MilestoneTooltipModal = ({ milestone }) => {
  const dispatch = useDispatch();

  const [taskStatusCounts, setTaskStatusCounts] = useState({});
  const [milestoneEstTime, setMilestoneEstTime] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [totalLogged, setTotalLogged] = useState(0);
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    if (milestone) {
      const handleMouseOver = async (id) => {
        const response = await dispatch.trashandStatus.getStatusData();
        const statuses = response.data;
        setStatuses(statuses);
        const statusMap = new Map(
          statuses.map((status) => [status._id, status.task_status])
        );

        const tasksByStatus = {};
        const statusCounts = {};

        milestone.tasks.forEach((task) => {
          const statusId = task.task_status._id;
          const statusName = statusMap.get(statusId) || "Unknown";
          if (!tasksByStatus[statusName]) {
            statusCounts[statusName] = 0;
          }
          statusCounts[statusName]++;
        });

        function timeStringToDecimal(timeString) {
          const [hours, minutes] = timeString?.split(":").map(Number);
          return parseInt(hours) * 60 + parseInt(minutes);
        }

        function formatTime(time) {
          const totalHours = Math.floor(time / 60);
          const totalMinutes = totalTimeSpent % 60;
          return `${totalHours}:${totalMinutes}`;
        }

        let totalEstimateTime = 0;
        let totalTimeSpent = 0;

        milestone.tasks.forEach((task) => {
          totalEstimateTime += timeStringToDecimal(task.estimate);
          task.timelogs.forEach((timelog) => {
            totalTimeSpent += timeStringToDecimal(timelog.task_time_spent);
          });
        });

        const formattedEstimateTime = formatTime(totalEstimateTime);
        const formattedLoggedTime = formatTime(totalTimeSpent);
        setTaskStatusCounts(statusCounts);
        setMilestoneEstTime(formattedEstimateTime);
        setTotalLogged(formattedLoggedTime);

        let totalTasks = 0;

        milestone.groups.forEach((group) => {
          totalTasks += group.tasks.length;
        });

        totalTasks += milestone.tasks.length;
        setTaskCount(totalTasks);
      };
      handleMouseOver();
    }
  }, [milestone]);

  return (
    <div>
      <ul className="flex flex-col gap-2">
        <li>
          <h2 className="font-extrabold text-xl">
            {milestone?.milestone?.title}
          </h2>
          <p className="text-[10px] opacity-50">
            {moment(milestone?.milestone?.createdAt).format(
              "DD-MM-YYYY hh:mm A"
            )}
          </p>
        </li>
        <li className="text-[#98A2B2] flex items-center">
          <p>Estimated time :</p>
          <TaskTimeLabel
            taskUsedTime={milestoneEstTime}
            taskEstimateTime={totalLogged}
          />
        </li>
        <li className="text-[#98A2B2]">
          No. of Groups:{" "}
          <span className="text-sm opacity text-black">
            {milestone?.groups?.length}
          </span>
        </li>
        <li className="text-[#98A2B2]">
          No. of Tasks: <span className="text-sm text-black">{taskCount}</span>
        </li>
      </ul>
      <ul className="flex gap-6 mt-5">
        {Object.entries(taskStatusCounts).map(([status, count]) => {
          const statusInfo = statuses.find((s) => s.task_status === status);
          const bgColor = statusInfo ? statusInfo.bg_color : "bg-gray-100";
          const textColor = statusInfo
            ? statusInfo.text_color
            : "text-gray-600";

          return (
            <li
              key={status}
              className={`${bgColor} text-${textColor} pt-1 pb-1 pl-2 pr-2 rounded-full`}
            >
              <p className="text-xss font-semibold">
                {count + " in " + status}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MilestoneTooltipModal;
