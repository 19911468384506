import React, { useEffect, useState } from "react";
import "./tasks.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditGroup({
  correspondingGroup,
  setShowEditGroupModal,
  onEditRender,
  setOnEditRender,
}) {
  const dispatch = useDispatch();

  const [editGroup, setEditGroup] = useState({
    group_name: correspondingGroup?.group_name
      ? correspondingGroup.group_name
      : "",
  });

  const updateGroup = async () => {
    const responseData = await dispatch.projectDetails.projectGroupUpdataion({
      project_id: correspondingGroup.project_id,
      group_id: correspondingGroup._id,
      ...editGroup,
    });
    if (responseData && responseData.success) {
      toast.success(responseData.group);
    } else {
      toast.error(responseData.message);
    }

    setShowEditGroupModal(false);
    setOnEditRender(!onEditRender);
  };

  return (
    <>
      {
        <div
          className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96 z-50 overscroll-contain`}
        >
          <div className="flex items-center justify-between p-4 pl-6">
            <h5 className="mb-0 leading-normal font-semibold">Update Group</h5>

            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowEditGroupModal(false)}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <hr className="w-full" />

          <div className="flex-grow p-4 pt-2 pl-6 overflow-y-auto">
            <label className="text-sm font-semibold">Title</label>
            <div className="flex rounded-md border mb-4">
              <div className="p-3 pr-0">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8125 0.531189C13.1231 0.531189 13.375 0.783031 13.375 1.09369V6.5963C13.9721 5.97281 14.7605 5.59369 15.625 5.59369C17.489 5.59369 19 7.35656 19 9.53119C19 11.7058 17.489 13.4687 15.625 13.4687C14.7605 13.4687 13.9721 13.0896 13.375 12.4661V12.9062C13.375 13.2168 13.1231 13.4687 12.8125 13.4687C12.5019 13.4687 12.25 13.2168 12.25 12.9062V1.09369C12.25 0.783031 12.5019 0.531189 12.8125 0.531189ZM15.625 12.3437C16.7112 12.3437 17.875 11.2535 17.875 9.53119C17.875 7.80894 16.7112 6.71869 15.625 6.71869C14.5388 6.71869 13.375 7.80894 13.375 9.53119C13.375 11.2535 14.5388 12.3437 15.625 12.3437ZM6.57614 0.908897C6.49844 0.685472 6.28924 0.534553 6.05272 0.531245C5.81619 0.527938 5.60287 0.672973 5.51896 0.894136L2.45509 8.96869H2.43209V9.02933L1.03674 12.7066C0.926526 12.9971 1.07264 13.3219 1.36309 13.4321C1.65355 13.5423 1.97836 13.3962 2.08856 13.1058L3.23148 10.0937H8.57974L9.62228 13.091C9.72434 13.3844 10.0449 13.5396 10.3384 13.4374C10.6318 13.3354 10.7869 13.0149 10.6848 12.7214L6.57614 0.908897ZM3.65835 8.96869L6.02188 2.73982L8.18845 8.96869H3.65835Z"
                    fill="#98A2B2"
                    stroke="#98A2B2"
                    strokeWidth="0.6"
                  />
                </svg>
              </div>

              <input
                className="p-2 w-full text-xs outline-none "
                type="text"
                value={editGroup.group_name}
                onChange={(e) =>
                  setEditGroup({ ...setEditGroup, group_name: e.target.value })
                }
                placeholder="Enter Title"
              />
            </div>

            <label className="text-sm font-semibold">Description</label>
            <div className="mb-5 h-60 common-editor   rounded-xl relative ">
              <ReactQuill
                className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                theme="snow"
                too
                // value={editTask.task_description}
              />
            </div>

            <label className="text-sm font-semibold">Milestone</label>
            <div className="flex rounded-md border mb-4">
              <input
                className="p-2 w-full text-xs outline-none "
                type="text"
                value={correspondingGroup.milestone_id.title}
                placeholder="Enter Milestone"
                readOnly
              />
            </div>

            <div className="flex justify-between space-x-3 mb-4">
              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Overview</label>
                <div className="">
                  <div className="flex items-center gap-1">
                    <label className="text-sm text-gray-400 font-medium">
                      No. of Tasks:
                    </label>
                    <label className="text-sm font-semibold">
                      {correspondingGroup.taskCount}
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <label className="text-sm text-gray-400 font-medium">
                      Estimated Time:
                    </label>
                    <label className="text-sm font-semibold">4</label>
                  </div>
                  <div className="flex items-center gap-1">
                    <label className="text-sm text-gray-400 font-medium">
                      Last Updated:
                    </label>
                    <label className="text-sm font-semibold">
                      Yesterday 2pm{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={updateGroup}
              className="border border-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold text-white py-2 px-5 flex gap-2 items-center"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                  fill="#ffffff"
                />
              </svg>
              Update Group
            </button>
          </div>
        </div>
        // </div>
      }
    </>
  );
}

export default EditGroup;
