import React, { memo, useState } from 'react';
import moment from 'moment';
import ModalLayout from './ModalLayout';
import ProjectInfo from '../../containers/pages/projects/ProjectCreate';
import AddResources from '../../containers/pages/projects/AddResources';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HeaderStrip = memo(({ page, getStatus, handleChange, dataStatus, filterDate, projectListData, searchSelect, projectsData, startDate, endDate, isWeekday, onChange, projectSearch }) => {

  const [headerTitle, setHeaderTitle] = useState("Ongoing")
  const [open, SetOpenModal] = useState(false);
  const [stepOne, setStepOne] = useState(true);


  const nextStep = (stepComplete) => {
    if (!stepComplete) {
      setStepOne(false);
    } else {
      setStepOne(true);
    }
  }

  const headerData = [
    {
      id: 1,
      label: "Ongoing",
      status: "ongoing"
    },
    {
      id: 2,
      label: "Completed",
      status: "complete"
    },
    {
      id: 3,
      label: "On Hold",
      status: "hold"
    },
    {
      id: 4,
      label: "All",
      status: "all"
    }
  ]

  const handleModal = () => {
    SetOpenModal(!open);
  }


  return (
    <>
      <div className="w-full sm:flex sm:items-center sm:justify-between">
        {
          page === "projectPage" ?

            <div className="px-6">
              <div className="flex bg-[#F6F6F6] w-fit rounded-full items-center last:pb-0">
                {headerData && headerData.map((item) =>
                  <button key={item.id} onClick={() => { getStatus(item.status); setHeaderTitle(item.label) }} className={`px-3 py-2 rounded-full ${dataStatus === item.status ? "bg-white h-full w-full" : ""} border-4 lg:px-6 border-[#F6F6F6] text-[13px] lg:text-sm text-balck transition duration-300 ease-out w-max ${item.label === "All" ? "px-6 lg:px-10" : ""}`}>{item.label}</button>
                )}
              </div>
            </div>
            :
            null
        }

        <div className="px-6 pt-6">
          <div className="flex flex-wrap items-center w-full justify-between pb-6">

            {/* <div className="mb-2 sm:mb-0">
              {page === "projectPage" ?
                <h3 className="font-title text-xl text-title font-medium">{headerTitle}&nbsp;Projects&nbsp;({projectsData && projectsData.totalcount})</h3>
                :
                <h3 className="font-title text-xl text-title font-medium">People</h3>
              }
            </div> */}

            <div className="flex flex-wrap items-center w-full sm:w-auto">
              {
                page === "projectPage" ?

                  <div className="mr-2 custom-select-wrapper relative sm:flex-row sm:w-auto xl:w-375">
                    <div className="absolute top-2/4 -translate-y-2/4 right-3 xl:right-5 z-10">
                      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.75 15.9497L11.4927 12.4444M11.4927 12.4444C12.0499 11.8448 12.4919 11.1329 12.7934 10.3495C13.0949 9.56612 13.2501 8.72646 13.2501 7.8785C13.2501 7.03054 13.0949 6.19088 12.7934 5.40747C12.4919 4.62406 12.0499 3.91223 11.4927 3.31263C10.9356 2.71303 10.2741 2.23741 9.54613 1.91291C8.81816 1.5884 8.03792 1.42139 7.24997 1.42139C6.46202 1.42139 5.68178 1.5884 4.95381 1.91291C4.22584 2.23741 3.56439 2.71303 3.00722 3.31263C1.88197 4.52358 1.24982 6.16597 1.24982 7.8785C1.24982 9.59104 1.88197 11.2334 3.00722 12.4444C4.13247 13.6553 5.65863 14.3356 7.24997 14.3356C8.84131 14.3356 10.3675 13.6553 11.4927 12.4444Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <input placeholder='Search' className="appearance-none rounded-full focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear  border text-xs px-6 md:py-3 w-full placeholder-secondary-grey-300 text-secondary-grey-300 border-secondary-grey-400 h-8 xl:py-5" type="text"
                      onChange={(evt) => { projectSearch(evt.target.value) }} />
                  </div>
                  :
                  <div className="block sm:flex items-center">
                    <p className="text-md text-black font-medium inline-block mr-5 mt-2 sm:mt-0">Selected date:  <span></span>{startDate && moment(startDate).format('MMMM')} {startDate && moment(startDate).format('DD')} - {endDate && moment(endDate).format('DD')}</p>
                    <div className="relative mb-4 sm:mb-0 mr-2 mt-4 sm:mt-0">
                      <div>
                        <DatePicker className="custom-datepicker-wrapper relative appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-xs border rounded-md px-3 pr-8 w-full sm:w-56 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 ring-transparent ring-0 h-8"
                          onChange={onChange}
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          endDate={endDate}
                          selectsRange
                          filterDate={isWeekday}
                          shouldCloseOnSelect={true}
                        />
                        <span className="cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.04708 0.0437498C2.90958 0.106249 2.78771 0.231247 2.71897 0.381245C2.66272 0.503119 2.65647 0.590617 2.65647 1.25623V1.99997H2.16899C1.59714 1.99997 1.41902 2.03747 1.04716 2.2281C0.709672 2.40309 0.403435 2.70934 0.228442 3.04684C-0.0184232 3.52495 0.000326034 3.05309 0.000326034 8.99988C0.000326034 14.9467 -0.0184232 14.4748 0.228442 14.9529C0.403435 15.2904 0.709672 15.5967 1.05028 15.7748C1.51901 16.0154 0.972161 15.9998 8 15.9998C15.0278 15.9998 14.481 16.0154 14.9497 15.7748C15.2903 15.5967 15.5966 15.2904 15.7716 14.9529C16.0184 14.4748 15.9997 14.9467 15.9997 8.99988C15.9997 3.05309 16.0184 3.52495 15.7716 3.04684C15.5966 2.70934 15.2903 2.40309 14.9528 2.2281C14.581 2.03747 14.4029 1.99997 13.831 1.99997H13.3435V1.25623C13.3435 0.521869 13.3435 0.509369 13.2654 0.353121C13.2154 0.253121 13.1373 0.159373 13.056 0.106249C12.9279 0.015625 12.9248 0.015625 12.3717 0.00625038C11.7592 -0.00625038 11.678 0.0124998 11.4936 0.209373C11.3342 0.37812 11.3124 0.503119 11.3124 1.28436V1.99997H8H4.68763V1.29373C4.68763 0.521869 4.67201 0.412495 4.52514 0.234372C4.35015 0.0249996 4.25953 0 3.6658 0C3.30019 0.00312519 3.1127 0.015625 3.04708 0.0437498ZM14.6497 10.4436L14.6404 14.1717L14.5529 14.3186C14.4966 14.4154 14.4154 14.4967 14.3185 14.5529L14.1716 14.6404H8H1.82838L1.68151 14.5529C1.58464 14.4967 1.50339 14.4154 1.44714 14.3186L1.35965 14.1717L1.35027 10.4436L1.34402 6.71866H8H14.656L14.6497 10.4436Z" fill="#54585D"></path></svg></span>
                      </div>
                    </div>
                  </div>
              }
              {/* <div className="flex items-center">
                {
                  page === "projectPage" ?
                    <button onClick={() => handleModal()} className="font-title flex items-center bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-4 rounded-md">
                      <span className="text-xl text-white -mt-[3px] flex mr-1">+</span>Add Project
                    </button>
                    :
                    null
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <ModalLayout closeModal={handleModal} open={open}>
        {
          page === "projectPage" ?
            <>
              {stepOne ?
                <ProjectInfo nextStep={nextStep} />
                :
                <AddResources closeModal={handleModal} />}
            </> : null
        }
      </ModalLayout>

    </>
  )
});

export default HeaderStrip