import React, { useState, useEffect } from "react";
import TaskBoardLists from "./TaskBoardLists";
import Select, { components } from "react-select";

export default function TaskBoard({
  data,
  mileStones,
  currentMilestone,
  taskData,
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "314.04px",
      minHeight: "27px",
      border: "none",
      borderBottom: "1px solid #ccc",
      borderRadius: "0",
      boxShadow: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      padding: "8px 12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
  };

  const [options, setOptions] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState({});

  useEffect(() => {
    if (mileStones && mileStones?.length > 0) {
      const apiOptions = mileStones.map((item) => ({
        value: item._id,
        label: item.title,
      }));
      setOptions(apiOptions);
      const defaultValue = apiOptions.find(
        (item) => item.value === currentMilestone[0].milestone._id 
      );
      setSelectedOptionId(defaultValue);
    }
  }, [mileStones, currentMilestone]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptionId(selectedOption);
  };

  // Custom Option Component
  const CustomOption = (props) => {
    const { data } = props;
    const isCurrent = data.value === currentMilestone[0].milestone._id && currentMilestone[0].milestone.is_current_milestone;

    return (
      <components.Option {...props}>
        <span>{data.label}</span>
        {isCurrent && (
          <button className="bg-[#1E7400] w-[60px] rounded text-white text-[11px]">
            Current
          </button>
        )}
      </components.Option>
    );
  };

  return (
    <>
      <div className="mb-6 pl-3">
        <p className="text-[#6E6E6E] font-medium text-xs pb-2">
          Current Milestone
        </p>
        <div className="flex gap-7 items-end">
          <Select
            placeholder="Select Status"
            value={selectedOptionId}
            options={options}
            styles={customStyles}
            className="text-[29px] font-medium"
            onChange={handleSelectChange}
            components={{
              Option: CustomOption,
              DropdownIndicator: () => (
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-3 mr-2"
                >
                  <path
                    d="M5 6.66699L10 0.000326157L0 0.000326157L5 6.66699Z"
                    fill="#656A6F"
                  />
                </svg>
              ),
            }}
          />
          {/* <button className="flex bg-[#1768E5] py-3 px-2 rounded-md items-center justify-center text-white font-semibold text-[13px] border-none">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M11.6388 0.454736C11.8589 0.253569 12.1518 0.142356 12.4557 0.144563C12.7596 0.146771 13.0507 0.262226 13.2676 0.466567C13.4846 0.670908 13.6103 0.948152 13.6183 1.2398C13.6264 1.53144 13.5161 1.81468 13.3107 2.02974L7.07633 9.51474C6.96913 9.62558 6.83974 9.71454 6.69591 9.77629C6.55208 9.83803 6.39675 9.8713 6.23922 9.8741C6.08169 9.8769 5.92519 9.84918 5.77907 9.79259C5.63296 9.73599 5.50023 9.6517 5.38883 9.54474L1.25445 5.57574C1.13932 5.47274 1.04697 5.34854 0.982919 5.21054C0.918869 5.07254 0.884428 4.92357 0.881652 4.77252C0.878876 4.62147 0.907821 4.47142 0.96676 4.33134C1.0257 4.19126 1.11343 4.06401 1.2247 3.95718C1.33598 3.85035 1.46854 3.76613 1.61446 3.70955C1.76037 3.65297 1.91667 3.62518 2.07402 3.62785C2.23137 3.63051 2.38654 3.66358 2.53029 3.72506C2.67404 3.78655 2.80342 3.87521 2.9107 3.98574L6.18258 7.12524L11.6091 0.487736L11.6388 0.454736Z"
                fill="white"
              />
            </svg>
            Apply Changes
          </button> */}
        </div>
      </div>
      <ul className="flex gap-4 overflow-x-auto">
        <TaskBoardLists
          data={data}
          selectedMilestoneId={selectedOptionId?.value}
        />
      </ul>
    </>
  );
}
