import React, { useState } from 'react'
import Accordion from '../Common/Accordian';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../Utilities/Loader';

const Roadmap = ({ DetailedProjectData, projectRoadmapTaskcreation, projectRoadmapIssuecreation, projectRoadmapIssuedelete, projectRoadmapIssueupdate, projectRoadmapSprintDelete }) => {

  const roadmapData = DetailedProjectData && DetailedProjectData.project &&
    DetailedProjectData.project.roadmap;

  const [activeIndex, setActiveIndex] = useState(0);
  const [newSprintLoader, setNewSprintLoader] = useState(false);
  const [deleteSprintLoader, setDeleteSprintLoader] = useState(false);

  const [subTaskLoader, setSubTaskLoaderLoader] = useState(false);
  const [deletesubTaskLoader, deleteSetSubTaskLoaderLoader] = useState(false);
  const [updatesubTaskLoader, updateSetSubTaskLoaderLoader] = useState(false);

  const addSprint = async () => {
    try {
      setNewSprintLoader(true);
      const responseData = await projectRoadmapTaskcreation({
        project_id: DetailedProjectData.project._id, title: `Sprint ${roadmapData.length + 1}`,
      });
      setNewSprintLoader(false);
      if (responseData && responseData.success) {
        toast.success("Project sprint added successfully");
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      setNewSprintLoader(false);
      toast.error(err.error);
    }
  }



  const addIssue = async (roadmapID) => {
    try {
      setSubTaskLoaderLoader(roadmapID);
      const responseData = await projectRoadmapIssuecreation({
        project_id: DetailedProjectData.project._id,
        roadmapID: roadmapID,
        title: "Issue Title",
        description: "Issue Description "
      });
      setSubTaskLoaderLoader(false);
      if (responseData && responseData.success) {
        toast.success("Project issue added successfully");
      } else {
        toast.error(responseData.message);
      }

    } catch (err) {
      setSubTaskLoaderLoader(false);
      toast.error(err.error);

    }
  }


  const deleteSprint = async (roadmapID) => {
    try {
      if (window.confirm("Are you sure to delete the item?")) {
        setDeleteSprintLoader(roadmapID);
        const responseData = await projectRoadmapSprintDelete({
          project_id: DetailedProjectData.project._id,
          roadmapID: roadmapID
        });
        setDeleteSprintLoader(false);
        if (responseData && responseData.success) {
          toast.success("Project sprint deleted successfully");
        } else {
          toast.error(responseData.message);
        }
      }

    } catch (err) {
      setDeleteSprintLoader(false);
      toast.error(err.error);

    }
  }



  const updateSubItem = async (data, roadmapID, body) => {
    try {
      updateSetSubTaskLoaderLoader(data._id);
      const responseData = await projectRoadmapIssueupdate({
        project_id: DetailedProjectData.project._id,
        roadmapID: roadmapID,
        subRoadID: data._id,
        ...body
      });
      if (responseData && responseData.success) {
        toast.success("Project issue updated successfully");
      } else {
        toast.error(responseData.message);
      }
      updateSetSubTaskLoaderLoader(false);

      return responseData

    } catch (err) {
      toast.error(err.error);
      updateSetSubTaskLoaderLoader(false);
      return err

    }
  }

  const deleteSubItem = async (data, roadmapID) => {
    try {
      if (window.confirm("Are you sure to delete the item?")) {
        deleteSetSubTaskLoaderLoader(data._id);
        const responseData = await projectRoadmapIssuedelete({
          project_id: DetailedProjectData.project._id,
          roadmapID: roadmapID,
          subRoadID: data._id
        });
        if (responseData && responseData.success) {
          toast.success("Project issue deleted successfully");
        } else {
          toast.error(responseData.message);
        }
        deleteSetSubTaskLoaderLoader(false);
        return responseData;
      }

    } catch (err) {
      console.log(err);
      toast.error(err.error);
      deleteSetSubTaskLoaderLoader(false);
      return err
    }
  }


  const setIndex = (ind) => {
    setActiveIndex(ind);

  }


  let loopindx = 0;

  const roadmaphtml = roadmapData && roadmapData.length > 0 && roadmapData.map((item, index) => {
    const subtaskdata = item && item.update && item.update.length > 0 && item.update.map((updateitem, updateitemind) => {
      const accordian = (<Accordion
        data={updateitem}
        title={updateitem.title}
        index={loopindx}
        activeIndex={activeIndex}
        roadmapId={item._id}
        updateSubItem={updateSubItem}
        deleteSubItem={deleteSubItem}
        setActiveIndex={setIndex}
        deletesubTaskLoader={deletesubTaskLoader}
        updatesubTaskLoader={updatesubTaskLoader}
      >
        {updateitem && updateitem.description}
      </Accordion>);
      loopindx++;
      return accordian;
    });

    return (<div key={index} className="shadow-3xl rounded-none mb-4">
      <div className="flex items-center lg:flex-row justify-between mb-3">
        <h3 className="font-title text-sm text-black text-title font-semibold">Sprint {index + 1}</h3>
        
          <button disabled={deleteSprintLoader === item._id} onClick={() => { deleteSprint(item._id) }} className="opacity-50 hover:opacity-95">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.0833 14H2.91666C2.27266 14 1.74999 13.4773 1.74999 12.8333V2.91667H1.16666V1.75H4.66666V0.875C4.66666 0.392583 5.05924 0 5.54166 0H8.45832C8.93957 0 9.33332 0.391417 9.33332 0.875V1.75H12.8333V2.91667H12.25V12.8333C12.25 13.4773 11.7273 14 11.0833 14ZM11.0833 2.91667H2.91666V12.5417C2.91666 12.7027 3.04732 12.8333 3.20832 12.8333H10.7917C10.9527 12.8333 11.0833 12.7027 11.0833 12.5417V2.91667ZM5.83332 5.25C5.83332 4.928 5.57199 4.66667 5.24999 4.66667C4.92799 4.66667 4.66666 4.928 4.66666 5.25V10.5C4.66666 10.822 4.92799 11.0833 5.24999 11.0833C5.57199 11.0833 5.83332 10.822 5.83332 10.5V5.25ZM9.33332 5.25C9.33332 4.928 9.07199 4.66667 8.74999 4.66667C8.42799 4.66667 8.16666 4.928 8.16666 5.25V10.5C8.16666 10.822 8.42799 11.0833 8.74999 11.0833C9.07199 11.0833 9.33332 10.822 9.33332 10.5V5.25ZM8.16666 1.16667H5.83332V1.75H8.16666V1.16667Z" fill="#F74051"></path></svg>
            {deleteSprintLoader === item._id ? <Loader /> : null}
          </button>
        
      </div>
   
      <div className="ml-5 border-borderColor-100 pl-3 pb-3">
        {subtaskdata}
        <button disabled={subTaskLoader === item._id} onClick={() => { addIssue(item._id); }} className="font-title flex items-center border  text-primary-navyBlue text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 p-4 px-3 rounded-md font-medium ">
          <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.66495 2.66666H7.33188V7.33332H2.66614V8.66666H7.33188V13.3333H8.66495V8.66666H13.3307V7.33332H8.66495V2.66666Z" fill="#1768E5"></path></svg>
          <span className="font-base text-primary-navyBlue font-medium ">Add Sub Task</span>
          {subTaskLoader === item._id ? <Loader /> : null}
        </button>
      </div>
     

    </div>)

  }) || <div className="text-sm font-medium text-left py-3 text-gray-500">No data</div>;




  return (
    <>
      <div>
        <div className='flex flex-col'>
          {roadmaphtml}
        </div>
        <button disabled={newSprintLoader} onClick={() => { addSprint(); }} className="font-title flex items-center bg-primary-navyBlue text-white text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 h-8 px-4 rounded-md">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.66495 2.66666H7.33188V7.33332H2.66614V8.66666H7.33188V13.3333H8.66495V8.66666H13.3307V7.33332H8.66495V2.66666Z" fill="white"></path></svg>
          <span className="ml-1">Add New Sprint</span>
          {newSprintLoader ? <Loader /> : null}
        </button>
      </div>
    </>
  );
}
const mapState = (state) => ({

});
const mapDispatch = (dispatch) => ({
  projectRoadmapTaskcreation: dispatch.projectDetails.projectRoadmapTaskcreation,
  projectRoadmapIssuecreation: dispatch.projectDetails.projectRoadmapIssuecreation,
  projectRoadmapIssuedelete: dispatch.projectDetails.projectRoadmapIssuedelete,
  projectRoadmapIssueupdate: dispatch.projectDetails.projectRoadmapIssueupdate,
  projectRoadmapSprintDelete: dispatch.projectDetails.projectRoadmapSprintDelete
});


export default connect(mapState, mapDispatch)(Roadmap)