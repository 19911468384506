import React, { useState, useEffect } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./custom-calendar.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip as ReactTooltip } from "react-tooltip";


const UserCard = ({ user, onRender, setOnRender }) => {
  const dispatch = useDispatch()
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState({});

  const handleSessionClick = (date, session) => {
    setSelectedSessions((prev) => {
      const newState = { ...prev };

      if (newState[date]?.[session]) {
        delete newState[date][session];

        if (Object.keys(newState[date]).length === 0) {
          delete newState[date];
        }
      } else {
        if (!newState[date]) {
          newState[date] = {};
        }
        newState[date][session] = true;
      }

      return newState;
    });
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const bookings = Object.keys(selectedSessions).map((date) => {
      const sessions = selectedSessions[date];
      const availability_booking_session = {};

      if (sessions.Session1) {
        availability_booking_session.Session1 = "booked";
      }
      if (sessions.Session2) {
        availability_booking_session.Session2 = "booked";
      }
      return {
        date,
        availability_booking_session: [availability_booking_session],
      };
    });

    const response = await dispatch.people.createUserBookings({
      user_id: user.id,
      bookedBy: "6281ec7caefb6d7146f86942",
      bookings: bookings,
    })
    if (response.success) {
      toast.success(response.message)
      setOnRender(!onRender)
    }
    setIsAssignModal(!isAssignModal);
  };

  const handleButtonClick = (skypeId) => {
    console.log(skypeId)
    if(skypeId !== "NA"){
      window.location.href = `skype:${skypeId}?chat`;
    }
    else{
      toast.error("Add Skype Name First!!")
    }
  };

  return (
    <div className="bg-white border px-4 py-6 rounded-xl shadow-sm flex flex-col gap-2">
      <div className="flex justify-start items-center gap-4 border-b-[1px] pb-6">
        <img
          src={`https://i.pravatar.cc/100?img=${user.id}`}
          alt={user.name}
          className="w-12 h-12 rounded-full"
        />
        <div>
          <h3 className="font-semibold text-sm">{user.name}</h3>
          <p className="text-gray-600 text-xs">{user.role}</p>
        </div>
      </div>
      <p className="text-[10px  ] font-light text-gray-500 mt-4">
        Available Dates
      </p>
      <p className="text-base font-medium flex items-center gap-2">
        <table className="table-auto">
          <tbody>
            {user.availability?.map((avail, availIndex) => (
              <tr
                className="text-sm font-normal text-[#909090]"
                key={availIndex}
              >
                <td className="pr-5">{`${availIndex + 1}. ${moment(avail.date).format("DD-MM-YYYY")}`}</td>
                <td className="pr-2">
                  {avail.availability_booking_session?.Session1 !== "booked" ? (
                    <div data-tooltip-id="available-tooltip">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <rect
                          width="15.9"
                          height="15.981"
                          rx="7.95"
                          fill="#D9EDBF"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div data-tooltip-id="notavailable-tooltip">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <rect
                          width="15.9"
                          height="15.981"
                          rx="7.95"
                          fill="#FFB996"
                        />
                      </svg>
                    </div>
                  )}
                </td>
                <td>
                  {avail.availability_booking_session?.Session2 !== "booked" ? (
                    <div className="cursor-pointer" data-tooltip-id="available-tooltip">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="15.9"
                          height="15.981"
                          rx="7.95"
                          fill="#D9EDBF"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="cursor-pointer" data-tooltip-id="notavailable-tooltip">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="15.9"
                          height="15.981"
                          rx="7.95"
                          fill="#FFB996"
                        />
                      </svg>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </p>
      <p className="text-base font-medium">{user.project}</p>
      <div className="flex space-x-2 border-t pt-4 mt-4 items-center justify-between">
        <button onClick={() => handleButtonClick(user?.skypeId)} className="px-5 py-2 text-sm sm:text-base bg-white border text-black rounded-lg flex items-center gap-1 hover:bg-gray-100  md:text-sm md:p-3 lg:px-5 lg:py-2 lg:text-base">
          <svg
            width="15"
            height="15"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_637_1494)">
              <path
                d="M15.0417 2.375C15.6716 2.375 16.2757 2.62522 16.7211 3.07062C17.1665 3.51602 17.4167 4.12011 17.4167 4.75V12.6667C17.4167 13.2966 17.1665 13.9006 16.7211 14.346C16.2757 14.7914 15.6716 15.0417 15.0417 15.0417H5.8053L3.16668 17.0208C2.51434 17.5101 1.58334 17.0446 1.58334 16.2292V4.75C1.58334 4.12011 1.83357 3.51602 2.27896 3.07062C2.72436 2.62522 3.32845 2.375 3.95834 2.375H15.0417ZM15.0417 3.95833H3.95834C3.74838 3.95833 3.54702 4.04174 3.39855 4.19021C3.25008 4.33867 3.16668 4.54004 3.16668 4.75V15.0417L4.8553 13.775C5.12937 13.5694 5.46272 13.4583 5.8053 13.4583H15.0417C15.2516 13.4583 15.453 13.3749 15.6015 13.2265C15.7499 13.078 15.8333 12.8766 15.8333 12.6667V4.75C15.8333 4.54004 15.7499 4.33867 15.6015 4.19021C15.453 4.04174 15.2516 3.95833 15.0417 3.95833ZM8.70834 9.5C8.91012 9.50022 9.1042 9.57749 9.25093 9.716C9.39765 9.85452 9.48595 10.0438 9.49777 10.2453C9.5096 10.4467 9.44406 10.6451 9.31455 10.7998C9.18504 10.9545 9.00133 11.054 8.80097 11.0778L8.70834 11.0833H6.33334C6.13156 11.0831 5.93748 11.0058 5.79076 10.8673C5.64404 10.7288 5.55574 10.5395 5.54392 10.3381C5.53209 10.1366 5.59763 9.93828 5.72714 9.78355C5.85665 9.62882 6.04035 9.52937 6.24072 9.50554L6.33334 9.5H8.70834ZM12.6667 6.33333C12.8766 6.33333 13.078 6.41674 13.2265 6.56521C13.3749 6.71367 13.4583 6.91504 13.4583 7.125C13.4583 7.33496 13.3749 7.53633 13.2265 7.68479C13.078 7.83326 12.8766 7.91667 12.6667 7.91667H6.33334C6.12338 7.91667 5.92202 7.83326 5.77355 7.68479C5.62508 7.53633 5.54168 7.33496 5.54168 7.125C5.54168 6.91504 5.62508 6.71367 5.77355 6.56521C5.92202 6.41674 6.12338 6.33333 6.33334 6.33333H12.6667Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_637_1494">
                <rect width="19" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Message
        </button>
        <button
          onClick={() => setIsAssignModal(!isAssignModal)}
          className="px-5 py-2 text-sm bg-green-500 text-white rounded-lg flex items-center gap-1 hover:bg-green-600 md:text-sm md:p-3 lg:px-5 lg:py-2 lg:text-base"
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 21C7.8 21 4 17.2 4 12.5C4 7.8 7.8 4 12.5 4C17.2 4 21 7.8 21 12.5C21 17.2 17.2 21 12.5 21ZM12.5 5C8.35 5 5 8.35 5 12.5C5 16.65 8.35 20 12.5 20C16.65 20 20 16.65 20 12.5C20 8.35 16.65 5 12.5 5Z"
              fill="white"
            />
            <path
              d="M12.35 17.3504L11.65 16.6504L15.8 12.5004L11.65 8.35039L12.35 7.65039L17.2 12.5004L12.35 17.3504Z"
              fill="white"
            />
            <path d="M8 12H16.5V13H8V12Z" fill="white" />
          </svg>
          Assign
        </button>
      </div>
      {isAssignModal && (
        <div>
          <div onClick={() => { setIsAssignModal(!isAssignModal) }} className="fixed inset-0 bg-black opacity-30 z-[5]"></div>
          <div className="fixed w-full left-0  h-screen sm:fixed transition inset-20 sm:w-auto sm:h-auto flex justify-center items-center z-50">
            <div
              className="p-4 sm:p-7 border rounded-lg bg-white dark:text-white"
              role="alert"
            >
              <div>
                <div className="w-full flex items-center justify-end mb-2"><svg className="cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                  onClick={() => { setIsAssignModal(!isAssignModal) }}>
                  <path d="M0.758057 11.2638L6.00106 6.15874L11.2441 11.2638M11.2441 1.05371L6.00006 6.15874L0.758057 1.05371" stroke="#B9B9B9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </div>
                <h1 className="text-[#7C7C7C] text-2xl font-light">Set Availability for </h1>
                <p className="text-[#0E0E0E] text-sm font-normal mb-8">16 September 2022 - 20 September 2022</p>
                <div className="flex justify-start items-center gap-4 mb-8">
                  <img
                    src={`https://i.pravatar.cc/100?img=${user.id}`}
                    alt={user.name}
                    className="w-12 h-12 rounded-full"
                  />
                  <div>
                    <h3 className="font-semibold text-sm">{user.name}</h3>
                    <p className="text-gray-600 text-xs">{user.role}</p>
                  </div>
                </div>
                <p className="text-[#515151] text-sm font-normal mb-6">Select Slots</p>
                <table className="mb-10 m-4">
                  <tbody>
                    {" "}
                    {user.availability?.map((avail, availIndex) => (
                      <tr
                        className="pb-2 text-sm font-normal text-[#909090]"
                        key={availIndex}
                      >
                        <td className="pr-2 sm:pr-10">{`${availIndex + 1}. ${moment(avail.date).format("DD-MM-YYYY")}`} </td>
                        <td className="pr-2">
                          {avail.availability_booking_session.Session1 !== "booked" ? (
                            <button onClick={() => handleSessionClick(avail.date, 'Session1')} className="px-12 py-4 bg-[#D9EDBF] rounded-lg relative"> {selectedSessions[avail.date]?.Session1 &&
                              <svg className="absolute top-2 right-10" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"  >
                                <path d="M17.0325 0.491157C17.381 0.172643 17.8447 -0.00344405 18.3259 5.10515e-05C18.8071 0.00354615 19.268 0.186351 19.6114 0.509891C19.9549 0.83343 20.154 1.2724 20.1667 1.73417C20.1794 2.19594 20.0048 2.6444 19.6796 2.98491L9.80852 14.8362C9.63879 15.0117 9.43393 15.1525 9.20619 15.2503C8.97846 15.348 8.73253 15.4007 8.4831 15.4052C8.23368 15.4096 7.98588 15.3657 7.75454 15.2761C7.52319 15.1865 7.31304 15.053 7.13665 14.8837L0.590555 8.59941C0.408257 8.43634 0.26204 8.23969 0.160628 8.02119C0.0592155 7.80269 0.00468445 7.56682 0.00028876 7.32765C-0.00410693 7.08848 0.0417228 6.85091 0.135043 6.62911C0.228363 6.40732 0.367263 6.20584 0.543455 6.03669C0.719647 5.86755 0.929522 5.7342 1.16056 5.64462C1.3916 5.55503 1.63907 5.51103 1.8882 5.51525C2.13734 5.51947 2.38303 5.57182 2.61064 5.66918C2.83824 5.76653 3.04308 5.9069 3.21295 6.08191L8.39342 11.0528L16.9855 0.543408L17.0325 0.491157Z" fill="white" />
                              </svg>
                            }</button>
                          ) : (
                            <button className="px-12 py-4 bg-[#FFB996] rounded-lg"> </button>
                          )}
                        </td>
                        <td>
                          {avail.availability_booking_session.Session2 !== "booked" ? (
                            <button onClick={() => handleSessionClick(avail.date, 'Session2')} className="px-12 py-4 bg-[#D9EDBF] rounded-lg relative"> {selectedSessions[avail.date]?.Session2 &&
                              <svg className="absolute top-2 right-10" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"  >
                                <path d="M17.0325 0.491157C17.381 0.172643 17.8447 -0.00344405 18.3259 5.10515e-05C18.8071 0.00354615 19.268 0.186351 19.6114 0.509891C19.9549 0.83343 20.154 1.2724 20.1667 1.73417C20.1794 2.19594 20.0048 2.6444 19.6796 2.98491L9.80852 14.8362C9.63879 15.0117 9.43393 15.1525 9.20619 15.2503C8.97846 15.348 8.73253 15.4007 8.4831 15.4052C8.23368 15.4096 7.98588 15.3657 7.75454 15.2761C7.52319 15.1865 7.31304 15.053 7.13665 14.8837L0.590555 8.59941C0.408257 8.43634 0.26204 8.23969 0.160628 8.02119C0.0592155 7.80269 0.00468445 7.56682 0.00028876 7.32765C-0.00410693 7.08848 0.0417228 6.85091 0.135043 6.62911C0.228363 6.40732 0.367263 6.20584 0.543455 6.03669C0.719647 5.86755 0.929522 5.7342 1.16056 5.64462C1.3916 5.55503 1.63907 5.51103 1.8882 5.51525C2.13734 5.51947 2.38303 5.57182 2.61064 5.66918C2.83824 5.76653 3.04308 5.9069 3.21295 6.08191L8.39342 11.0528L16.9855 0.543408L17.0325 0.491157Z" fill="white" />
                              </svg>
                            }</button>
                          ) : (
                            <button className="px-12 py-4 bg-[#FFB996] rounded-lg"> </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex items-center justify-between">
                  <button onClick={() => { setIsAssignModal(!isAssignModal) }} className="text-black hover:border-gray-600 bg-white text-sm font-normal px-12 py-2 border rounded-lg hover:bg-gray-100">Cancel</button>
                  <button onClick={handleFormSubmit} className="text-[#FFFFFF] bg-[#50DA0F] text-base font-extrabold  px-14 py-2 border border-[#50DA0F] rounded-lg hover:bg-[#37a305]">Set</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ReactTooltip
        id="available-tooltip"
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
        }}
        place="bottom"
        content="Available"
      />
      <ReactTooltip
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
        }}
        id="notavailable-tooltip"
        place="bottom"
        content="Not Available"
      />
    </div>
  );
};

const getDefaultDateRange = () => {
  const startDate = moment().startOf('isoWeek');
  const endDate = moment().endOf('isoWeek').subtract(2, 'days');
  const defaultDates = [];

  for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date.add(1, 'day')) {
    defaultDates.push(date.clone().toDate());
  }

  return defaultDates;
};

function People2() {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(getDefaultDateRange);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [onRender, setOnRender] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const itemsPerPage = 9;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const response = await dispatch.people.getUserBookings({
        start_date: moment(dateRange[0]).startOf('day').toISOString(),
        end_date: moment(dateRange[dateRange.length - 1]).endOf('day').toISOString()
      });
      setUsers(response?.users);
      setFilteredUsers(response?.users);
      setIsLoading(false)
    }
    fetchData();
  }, [dateRange, onRender]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const designationsData = await dispatch.projects.designationSearch({});
      const roles = designationsData.data
        .filter(role => role.developerstatus)
        .map(role => role.name);
      setRoles(roles);
      setIsLoading(false)
    };
    fetchData();
  }, []);

  const handleDateChange = date => {
    const startDate = moment(date).startOf('isoWeek');
    const endDate = moment(date).endOf('isoWeek').subtract(2, 'days');
    const selectedDates = [];

    for (let currentDate = startDate; currentDate.isBefore(endDate) || currentDate.isSame(endDate); currentDate.add(1, 'day')) {
      selectedDates.push(currentDate.clone().toDate());
    }
    setDateRange(selectedDates);
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    if (selectedRole === 'All') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter(user => user.role === selectedRole));
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(term)));
    setCurrentPage(1);
  };

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-2 p-4">
      <div className="w-full md:w-min lg:w-max overflow-hidden p-2 bg-white rounded-2xl md:shadow-lg border">
        <div onClick={() => setIsFilterOpen(!isFilterOpen)} className="flex w-full justify-between items-center md:hidden">
          <p className="text-[#888888] text-base font-normal">Filters</p>
          <svg className={`transition-transform duration-500 ${isFilterOpen ? 'transform rotate-180' : ''}`} width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.855835 0.761475L6.46712 5.14223L12.0784 0.761475" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div className={`md:block ${isFilterOpen ? '' : 'hidden'}`}>
          <div className="w-full flex flex-col items-center">
            <Calendar
              onChange={handleDateChange}
              value={dateRange[0]}
              tileClassName={({ date, view }) => {
                if (view === "month" && dateRange.find(d => moment(d).isSame(date, 'day'))) {
                  return "bg-blue-500 text-white rounded-full";
                }
                return null;
              }}
            />
          </div>
          <div className="py-2">
            <p className="text-[#515151] text-sm font-normal py-2">Select Date range</p>
            <input type="text" className="w-full text-sm font-medium border rounded-lg px-4 py-1" readOnly value={`${moment(dateRange[0]).format('YYYY-MM-DD')} - ${moment(dateRange[dateRange.length - 1]).format('YYYY-MM-DD')}`} />
          </div>
          <p className="mt-3 text-[#515151] text-sm font-normal">Select Role</p>
          <select className="bg-transparent appearance-none border mt-2 p-2 rounded-lg w-full" onChange={handleRoleChange}>
            <option>All</option>
            {roles.map((name, index) => (
              <option key={index}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-full p-4 border rounded-3xl shadow-lg">
        <div className="flex flex-col items-start gap-4 sm:flex-row sm:justify-between sm:items-center">
          <div className="relative flex flex-col gap-4 p-1 text-xs">
            <p className="text-3xl font-light text-[#252525]">Availability for</p>
            <p className="text-sm font-normal text-[#0E0E0E]">
              {moment(dateRange[0]).format('DD MMMM YYYY')} - {moment(dateRange[dateRange.length - 1]).format('DD MMMM YYYY')}
            </p>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search by name"
              className="p-2 border rounded-lg text-sm"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <>
          {!isLoading && paginatedUsers.length > 0 ?
            <>
              <div className="flex-1 mt-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                {paginatedUsers.map((user) => (
                  <UserCard
                    key={user.id}
                    user={user}
                    isAssignModal={isAssignModal}
                    setIsAssignModal={setIsAssignModal}
                    setOnRender={setOnRender}
                    onRender={onRender} />
                ))}
              </div>
              <div className="flex justify-between items-center mt-4 pt-6">
                <span className="hidden sm:block">Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, filteredUsers.length)} of {filteredUsers.length} results</span>
                <span className="text-xs sm:hidden">Total {filteredUsers.length} results</span>
                <div className="flex gap-3">
                  <button
                    className="border px-4 p-2 rounded-xl text-black text-xs sm:text-sm hover:bg-gray-200"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  >
                    Previous
                  </button>
                  <button
                    className="border px-4 p-2 rounded-xl text-black text-xs sm:text-sm hover:bg-gray-200"
                    disabled={currentPage * itemsPerPage >= filteredUsers.length}
                    onClick={() => setCurrentPage(prev => prev + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </> :
            <div className="flex-1 mt-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 rounded-xl">
              {[...Array(10)].map((_, index) => (
                <Skeleton key={index} count={10} />
              ))}
            </div>
          }
        </>
      </div>
    </div>
  );
}

export default People2;