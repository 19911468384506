import React, { useState } from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import TaskComment from "./TaskComment";
import TaskReplyComment from "./TaskReplyComment";
import { toast } from "react-toastify";
import { projectTaskCommentCreationService } from "../../../../services/projectDetails";

function TaskComments({ task, usersData }) {
  const [comment, setComment] = useState("");
  const [commentReply, setCommentReply] = useState(false);
  const [toggleView, setToggleView] = useState(true);

  const toggleLogsView = () => {
    setToggleView(!toggleView);
  };

  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );

  const sanitizedContent = DOMPurify.sanitize(task?.task_description);

  const handleSubmitComment = async () => {
    const responseData = await projectTaskCommentCreationService({
      project_id: DetailedProjectData.project._id,
      task_id: task._id,
      userDetails: usersData._id,
      comments: comment,
      is_reply: commentReply,
    });

    if (responseData && responseData.status === 200) {
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
    }
  };

  return (
    <div className="w-full bg-[#F7F8FA] py-2 px-2 rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z"
              fill="#606C80"
            />
          </svg>
          <p className="text-sm font-semibold">
            Comments <span className="text-blue-600">25</span>
          </p>
        </div>
        <div className="flex items-center pb-1">
          <svg
            width="37"
            height="22"
            viewBox="0 0 37 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: toggleView ? "none" : "inline-block" }}
            onClick={toggleLogsView}
          >
            <path d="M22.5 11L14.5 5L14.5 17L22.5 11Z" fill="#656A6F" />
          </svg>
          <svg
            width="45"
            height="18"
            viewBox="0 0 45 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleLogsView}
            style={{ display: toggleView ? "inline-block" : "none" }}
          >
            <path d="M22.5 13L28.5 5L16.5 5L22.5 13Z" fill="#656A6F" />
          </svg>
        </div>
      </div>
      {toggleView && (
        <div className="my-8 mx-8">
          <div className="flex items-center gap-2 ">
            <input
              className="border rounded-md px-3 py-2.5 w-full text-xs outline-none"
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write your comment"
            />
            <button
              type="button"
              onClick={handleSubmitComment}
              className="bg-blue-600 px-4 py-2.5 text-white text-xs rounded-md border border-blue-500"
            >
              Submit
            </button>
          </div>
          <TaskComment
            task={task}
            setCommentReply={setCommentReply}
            commentReply={commentReply}
          />
          <div className="my-5">
            <TaskReplyComment />
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskComments;
