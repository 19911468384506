import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../Utilities/Loader";
import Technologies from "./Technologies";
import Requirement from "./Requirement";

const Overview = ({ DetailedProjectData, projectDescriptionChange }) => {
  //     const data =DetailedProjectData.data.project.description;
  //const [editor] = useState(() => withReact(createEditor()));
  const [value, setValue] = useState((DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.description) || "No data");
  const [visible, setvisibleisible] = useState("hide");
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("overview")


  const updateContent = async () => {
    try {
      setLoading(true);
      const responseData = await projectDescriptionChange({ description: value, project_id: DetailedProjectData.project._id });
      if (responseData && responseData.success) {
        toast.success("Project description updated");
        setLoading(false);
      } else {
        toast.error(responseData.message);
        setLoading(false);
      }

    } catch (err) {
      toast.error(err.error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.description) {
      setValue(DetailedProjectData.project.description);
    }

  }, [DetailedProjectData])

  const tabTitle = [
    {
      id: '1',
      label: 'Details',
      status: 'overview'
    },
    {
      id: '2',
      label: 'Requirement',
      status: 'requirement'

    },
    {
      id: '3',
      label: 'Technologies',
      status: 'technologies'
    },
  ]

  const tabDetail = (DetailedProjectData) => {
    switch (status) {
      case "requirement":
        return <Requirement DetailedProjectData={DetailedProjectData} />
      case "technologies":
        return <Technologies DetailedProjectData={DetailedProjectData} />
    }
  }


  return (
    <>
      <header className="px-6 border-b">
        {tabTitle && tabTitle.map((item) => (
          <button key={item.id} onClick={() => setStatus(item.status)}
            className={` ${item.status === status ? "text-secondary-blue-400 border-primary-navyBlue" : "border-transparent text-gray-500"}  border-b mr-3 sm:mr-8 font-title text-xs md:text-sm  pb-3 text-title font-normal`}>
            {item.label}
          </button>
        ))}
      </header>

      {
        status === "overview" ?
          <>
            <div className="h-screen">
              <div className="bg-secondary-grey-100 rounded-xl relative p-6 ">
                <div className="flex lg:flex-row justify-between pb-4 items-center absolute right-6 z-10 top-7 cursor-pointer ">
                  <div onClick={() => setvisibleisible("show")}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.75241 15.0427L0.00174713 16L0.960414 11.25L4.75241 15.0427ZM1.90308 10.3067L5.69508 14.0993L16.0017 3.79267L12.2091 0L1.90308 10.3067Z" fill="#B6BABD" />
                    </svg>
                  </div>
                </div>
                <p className={`font-base text-tiny font-normal absolute pr-12 ${visible === 'show' ? "invisible" : "visible"}`} >{value.replace(/(<([^>]+)>)/gi, "")} </p>
                <div className={`${visible === 'show' ? "visible" : "invisible"}`}>
                  <ReactQuill className="font-xl" theme="snow" value={value} onChange={setValue} />
                </div>
              </div>
              <button disabled={isLoading} onClick={() => { updateContent() }} className="mt-4 font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 p-4 rounded-md font-medium">Submit
                {isLoading ? <Loader /> : null}
              </button>
            </div>
          </>
          :
          <div className="">
            <div className={` ${status === "requirement" ? "py-0 " : "px-6 py-7"}`} >
              {DetailedProjectData ? tabDetail(DetailedProjectData) : null}
            </div>
          </div>
      }
    </>

  )
}


const mapState = (state) => ({

});
const mapDispatch = (dispatch) => ({
  projectDescriptionChange: dispatch.projectDetails.projectDescriptionChange
});

export default connect(mapState, mapDispatch)(Overview)


