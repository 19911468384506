import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
import "./styles.css";
import { TasksListComponent } from "./TasksListComponent";
import { useDispatch } from "react-redux";
import { projectTaskUpdationService } from "../../../services/projectDetails";
import GroupModal from "./GroupModal";
import AddTask from "./AddTask";
import TimeLogFilterData from "./TimeLogFilterData";
import FilterdData from "./FilteredData";
import moment from "moment";
import MilestoneTooltipModal from "./MilestoneTooltipModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MilestoneDetailsModal from "./MilestoneDetailsModal";
import TaskListOptions from "./TaskListOptions";

const TasksLists = ({
  onRender,
  data,
  taskData,
  setTaskData,
  showFilterTasks,
  isAddTaskShown,
  setAddTaskIsShown,
  filteredData,
  setFilteredData,
  timeLogFilteredData,
  showTimeLogFilterData,
  setCurrentMilestone,
  showMilestone,
  setShowMilestone,
  milestoneNames,
  setMilestoneNames,
}) => {
  const [mileStoneId, setMileStoneId] = useState("");
  const [mileStoneName, setMileStoneName] = useState("");
  const [milestoneContentVisible, setMilestoneContentVisible] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [renderer, setRenderer] = useState(false);
  const dispatch = useDispatch();
  const [expandedGroups, setExpandedGroups] = useState({});
  const [correspondingMilestone, setCorrespondingMilestone] = useState({});
  const [nullTasks, setNullTasks] = useState([]);
  const [nullTaskPageCount, setNulltaskPageCount] = useState(9);
  const [tasksToShowByMilestone, setTasksToShowByMilestone] = useState(5);
  const [groupTasksToShow, setGroupTasksToShow] = useState({});
  const [milestoneDetails, setMilestoneDetails] = useState(false);

  const [milestoneId, setMilestoneId] = useState(null);

  useEffect(() => {
    if (data && data.project && data.project.id) {
      const fetchMileStone = async () => {
        const response =
          await dispatch.newProjectDetails.getListTaskAndMilestones();
        setNullTasks(response?.data?.tasks);
        const milestoneResponse =
          await dispatch.newProjectDetails.getGetFirstMilestone();
        setShowMilestone([milestoneResponse.data]);
        const index = response?.data?.milestones.findIndex(
          (milestone) => milestone._id === milestoneResponse.data.milestone._id
        );
        if (index !== -1 && index !== 0) {
          // Get the first milestone
          const firstMilestone = response?.data?.milestones[index];

          // Create a new array with the first milestone at the start
          const updatedMilestones = [
            firstMilestone,
            ...response?.data?.milestones.filter((milestone, i) => i !== index),
          ];

          setMilestoneNames(updatedMilestones);
        } else {
          setMilestoneNames(response?.data?.milestones);
        }

        const milestonesLength = response?.data?.milestones?.length;
        const milestoneContentVisibility = new Array(milestonesLength).fill(
          false
        );
        if (milestonesLength > 0) {
          milestoneContentVisibility[0] = true;
        }
        setMilestoneContentVisible(milestoneContentVisibility);
        if (milestoneId) {
          fetchMilestoneDetails(milestoneId);
        }
      };
      fetchMileStone();
    }
  }, [data, renderer, onRender]);

  useEffect(() => {
    const task = document.querySelector(".tasks");
    const projId = document.querySelector(".all_tasks");
    const milestone = document.querySelectorAll(".milestones");
    if (task !== null) {
      const sortableTasks = new Sortable(task, {
        group: {
          name: "shared",
        },
        handle: ".handle",
        animation: 150,
        sort: true,
      });
      sortableTasks.options.onEnd = async function (evt) {
        const getGroups = await dispatch.projectDetails.getGroupList({
          project_id: projId.id,
        });
        const groupExistsCheck = getGroups?.filter(
          (item) => item._id === evt.to.id
        );

        if (groupExistsCheck?.length > 0) {
          const dragResponseData = await projectTaskUpdationService({
            project_id: projId.id,
            task_id: evt.item.id,
            group_id: evt.to.id,
            milestone_id: groupExistsCheck[0].milestone_id,
          });
        } else {
          const dragResponseData = await projectTaskUpdationService({
            project_id: projId.id,
            task_id: evt.item.id,
            milestone_id: evt.to.id,
          });
          if (evt.to.classList.contains("milestones")) {
            const targetQuery = evt.to.querySelector(".target");
            targetQuery?.insertBefore(evt.item, targetQuery.firstChild);
            const newSortableTask = new Sortable(evt.item.parentNode, {
              group: "shared",
              animation: 150,
            });
          }
        }
      };
      for (let i = 0; i < milestone?.length; ++i) {
        const sortableNestedTasks = new Sortable(milestone[i], {
          group: "shared",
          filter: ".filter",
          animation: 150,
        });
        sortableNestedTasks.options.onEnd = async function (evt) {
          const getGroups = await dispatch.projectDetails.getGroupList({
            project_id: projId.id,
          });
          const groupExistsCheck = getGroups?.filter(
            (item) => item._id === evt.to.id
          );
          if (evt.to.id === "fromTask") {
            const dropBackResponse = await projectTaskUpdationService({
              project_id: data?.project?.id,
              task_id: evt.item.id,
              milestone_id: null,
              group_id: null,
            });
          } else {
            if (groupExistsCheck?.length > 0) {
              const dropBackResponse = await projectTaskUpdationService({
                project_id: data?.project?.id,
                task_id: evt.item.id,
                milestone_id: groupExistsCheck[0].milestone_id,
                group_id: evt.to.id,
              });
            } else {
              const dropBackResponse = await projectTaskUpdationService({
                project_id: data?.project?.id,
                task_id: evt.item.id,
                milestone_id: evt.to.id,
                group_id: null,
              });
            }
          }
          if (evt.to.classList.contains("milestones")) {
            const targetQuery = evt.to.querySelector(".target");
            targetQuery?.insertBefore(evt.item, targetQuery.firstChild);
            const newSortableTask = new Sortable(evt.item.parentNode, {
              group: "shared",
              animation: 150,
            });
          }

          let element = document.getElementById(evt.item.id);
          // element.classList.remove("check")
          if (element.classList.contains("hidden")) {
            element.classList.remove("hidden");
          }
          // element.removeAttribute("draggable");
        };
      }
    }
  }, [milestoneContentVisible]);

  const toggleMilestoneHide = async (index, milestoneId) => {
    let element = document.getElementById(milestoneId);

    if (element && element.classList.contains("block")) {
      element.classList.remove("block");
    }
    if (element && element.classList.contains("hidden")) {
      element.classList.remove("hidden");
    }

    element.className += " hidden";
    const copyMilestoneContentVisible = [...milestoneContentVisible];
    copyMilestoneContentVisible[index] = !copyMilestoneContentVisible[index];
    setMilestoneContentVisible(copyMilestoneContentVisible);
  };

  const fetchMilestoneDetails = async (milestoneId) => {
    const response = await dispatch.newProjectDetails.getGetOneMilestone({
      milestone_id: milestoneId,
    });
    setShowMilestone((prevState) => {
      const existingMilestoneIndex = prevState.findIndex(
        (milestone) => milestone.milestone._id === milestoneId
      );

      if (existingMilestoneIndex !== -1) {
        const updatedMilestones = [...prevState];
        updatedMilestones[existingMilestoneIndex] = response.data;
        return updatedMilestones;
      } else {
        return [...prevState, response.data];
      }
    });
  };

  const toggleMilestoneView = async (index, milestoneId) => {
    setMilestoneId(milestoneId);
    let element = document.getElementById(milestoneId);
    if (element && element.classList.contains("hidden")) {
      element.classList.remove("hidden");
    } else {
      await fetchMilestoneDetails(milestoneId);
      if (element) {
        element.classList.add("block");
      }
    }

    const copyMilestoneContentVisible = [...milestoneContentVisible];
    copyMilestoneContentVisible[index] = !copyMilestoneContentVisible[index];
    setMilestoneContentVisible(copyMilestoneContentVisible);
  };

  const toggleGroupHide = (milestoneId, groupId) => {
    const key = `${milestoneId}_${groupId}`;
    let element = document.getElementById(groupId);
    if (element || element.classList.contains("hidden")) {
      element.classList.remove("hidden");
    }
    element.className += "block";

    setExpandedGroups((prevExpandedGroups) => {
      return {
        ...prevExpandedGroups,
        [key]: !prevExpandedGroups[key],
      };
    });
  };

  const toggleGroupView = (milestoneId, groupId) => {
    const key = `${milestoneId}_${groupId}`;
    let element = document.getElementById(groupId);

    if (element && element.classList.contains("block")) {
      element.classList.remove("block");
    }
    element.className += " hidden";

    setExpandedGroups((prevExpandedGroups) => {
      return {
        ...prevExpandedGroups,
        [key]: !prevExpandedGroups[key],
      };
    });
  };

  const handleGroupLoadMore = (groupId) => {
    setGroupTasksToShow((prevState) => ({
      ...prevState,
      [groupId]: (prevState[groupId] || 5) + 5,
    }));
  };

  return (
    <>
      <div className="w-full all_tasks" id={data?.project?.id}>
        {!showFilterTasks ? (
          <>
            <div className="mb-10">
              <div id="fromTask" className="tasks mb-4">
                {nullTasks?.slice(0, nullTaskPageCount).map((task, _index) => {
                  return (
                    <div id={task._id} key={task._id}>
                      {task.status_name !== "DELETE" && (
                        <TasksListComponent
                          renderer={renderer}
                          setRenderer={setRenderer}
                          data={data}
                          item={task}
                          taskData={taskData}
                          setTaskData={setTaskData}
                          handle="handle"
                          taskTime={task.timelog}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {nullTasks?.length > nullTaskPageCount && (
                <p
                  onClick={() => setNulltaskPageCount(nullTaskPageCount + 9)}
                  className="mt-1 font-bold text-xs pl-1 cursor-pointer flex items-center gap-1"
                >
                  <span className="text-[#5D97EF]">Load more</span>
                  <span> {nullTasks.length - nullTaskPageCount} tasks</span>
                </p>
              )}
            </div>

            {/* tasks inside milestone */}
            {milestoneNames?.map((milestone, index) => {
              const correspondingDetail = showMilestone.find(
                (detail) => detail.milestone._id === milestone._id
              );

              return (
                <div
                  className="px-4 bg-greyColor-100 rounded-xl mb-3 py-3"
                  key={milestone?._id}
                >
                  <div className="flex items-center justify-between filter check">
                    <p
                      className="filter text-grey-600 font-semibold text-sm pb-2 flex gap-2 cursor-pointer"
                      data-tooltip-id="milestone-tooltip"
                    >
                      <span
                        className=""
                        onClick={() => {
                          setMilestoneDetails(true);
                          setCorrespondingMilestone(milestone);
                        }}
                      >
                        {milestone?.title}
                      </span>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                          />
                        </svg>
                      </div>
                      {correspondingDetail?.milestone?.is_current_milestone && (
                        <button className="bg-[#1E7400] w-[60px] rounded text-white text-[11px]">
                          Current
                        </button>
                      )}
                    </p>
                    <div className="flex justify-between items-center text-xs space-x-5 filter text-blue-500 mb-2">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v12m6-6H6"
                          />
                        </svg>
                        <button
                          className="underline"
                          onClick={() => {
                            setShowAddTask(true);
                            setMileStoneId(milestone?._id);
                            setMileStoneName(milestone?.title);
                          }}
                        >
                          Add Task
                        </button>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v12m6-6H6"
                          />
                        </svg>
                        <button
                          className="underline"
                          onClick={() => {
                            setShowGroupModal(true);
                            setMileStoneId(milestone?._id);
                          }}
                        >
                          Add Group
                        </button>
                      </div>
                      <div>
                        <TaskListOptions
                          milestone={milestone}
                          data={data}
                          setCurrentMilestone={setCurrentMilestone}
                          currentMilestone={
                            correspondingDetail?.milestone?.is_current_milestone
                          }
                          setRenderer={setRenderer}
                          renderer={renderer}
                        />
                      </div>
                      {milestoneContentVisible[index] ? (
                        <svg
                          width="12"
                          height="8"
                          className="cursor-pointer select-none"
                          onClick={() => {
                            toggleMilestoneHide(index, milestone?._id);
                          }}
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 5.24537e-07L6.99382e-07 8L12 8L6 5.24537e-07Z"
                            fill="#656A6F"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="8"
                          height="12"
                          className="cursor-pointer select-none"
                          onClick={() => {
                            toggleMilestoneView(index, milestone._id);
                          }}
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 6L1.09677e-06 -9.5399e-08L9.53674e-07 12L8 6Z"
                            fill="#656A6F"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {correspondingDetail && (
                    <div id={milestone?._id}>
                      <ReactTooltip
                        id="milestone-tooltip"
                        variant="info"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          boxShadow: "8px 10px 5px 0px rgba(0,0,0,0.05)",
                        }}
                        content={
                          <MilestoneTooltipModal
                            milestone={correspondingDetail}
                          />
                        }
                      />
                      <div
                        className={`milestones ${
                          correspondingDetail?.tasks?.length === 0 &&
                          correspondingDetail?.groups?.length === 0
                            ? "pb-4"
                            : ""
                        }`}
                        id={milestone._id}
                      >
                        {/* tasks inside groups */}
                        {correspondingDetail?.groups?.map((group) => {
                          if (group.delete_status === "1") {
                            return (
                              <div className="m-2 group_view" key={group._id}>
                                <div className="border border-gray-300 rounded-lg p-2">
                                  <div className="flex justify-between pb-2">
                                    <div className="filter flex items-center">
                                      {!expandedGroups[
                                        `${group.milestone_id}_${group._id}`
                                      ] ? (
                                        <svg
                                          width="7"
                                          height="10"
                                          onClick={() =>
                                            toggleGroupHide(
                                              group.milestone_id,
                                              group._id
                                            )
                                          }
                                          className="mr-2 cursor-pointer"
                                          viewBox="0 0 7 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.66667 5L5.28493e-06 2.91409e-07L5.72205e-06 10L6.66667 5Z"
                                            fill="#656A6F"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="10"
                                          height="8"
                                          onClick={() =>
                                            toggleGroupView(
                                              group.milestone_id,
                                              group._id
                                            )
                                          }
                                          className="mr-2 cursor-pointer"
                                          viewBox="0 0 10 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5 7.33334L10 0.666677L0 0.666677L5 7.33334Z"
                                            fill="#656A6F"
                                          />
                                        </svg>
                                      )}
                                      <svg
                                        width="15"
                                        className="mr-2.5"
                                        height="14"
                                        viewBox="0 0 15 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.914 2.82702H7.88224L7.6768 2.18502C7.54362 1.80831 7.29657 1.48235 6.96989 1.25229C6.64321 1.02224 6.25307 0.899475 5.85352 0.901015H2.926C2.41519 0.901015 1.92531 1.10393 1.56411 1.46513C1.20292 1.82632 1 2.31621 1 2.82702V11.173C1 11.6838 1.20292 12.1737 1.56411 12.5349C1.92531 12.8961 2.41519 13.099 2.926 13.099H11.914C12.4248 13.099 12.9147 12.8961 13.2759 12.5349C13.6371 12.1737 13.84 11.6838 13.84 11.173V4.75302C13.84 4.24221 13.6371 3.75232 13.2759 3.39113C12.9147 3.02993 12.4248 2.82702 11.914 2.82702ZM12.556 11.173C12.556 11.3433 12.4884 11.5066 12.368 11.627C12.2476 11.7474 12.0843 11.815 11.914 11.815H2.926C2.75573 11.815 2.59244 11.7474 2.47204 11.627C2.35164 11.5066 2.284 11.3433 2.284 11.173V2.82702C2.284 2.65675 2.35164 2.49345 2.47204 2.37305C2.59244 2.25265 2.75573 2.18502 2.926 2.18502H5.85352C5.98811 2.18467 6.11941 2.22663 6.22885 2.30497C6.3383 2.38331 6.42035 2.49406 6.46342 2.62158L6.8101 3.67446C6.85317 3.80197 6.93522 3.91272 7.04467 3.99106C7.15411 4.0694 7.28541 4.11136 7.42 4.11102H11.914C12.0843 4.11102 12.2476 4.17865 12.368 4.29905C12.4884 4.41945 12.556 4.58275 12.556 4.75302V11.173Z"
                                          fill="#606C80"
                                          stroke="#606C80"
                                          strokeWidth="0.5"
                                        />
                                      </svg>
                                      <p className="filter groups text-tiny text-black-600 font-semibold">
                                        {group.group_name}
                                      </p>
                                    </div>
                                    <div className="flex mt-2">
                                      <div>
                                        <TaskListOptions
                                          groupTasks={group}
                                          setRenderer={setRenderer}
                                          renderer={renderer}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      group?.tasks?.length == 0 && "pb-2"
                                    } milestones hidden`}
                                    id={group?._id}
                                  >
                                    {group?.tasks.map((tasks) => {
                                      return (
                                        <div
                                          className="check"
                                          id={tasks._id}
                                          key={tasks._id}
                                        >
                                          <TasksListComponent
                                            renderer={renderer}
                                            setRenderer={setRenderer}
                                            data={data}
                                            item={tasks}
                                            taskData={taskData}
                                            setTaskData={setTaskData}
                                            handle="handle"
                                            taskTime={tasks.timelog}
                                          />
                                        </div>
                                      );
                                    })}
                                    <div>
                                      {groupTasksToShow[group._id] <
                                        group?.length &&
                                        group?.length >= 5 && (
                                          <p
                                            onClick={() =>
                                              handleGroupLoadMore(group._id)
                                            }
                                            className="mt-1 pl-1 font-bold text-xs cursor-pointer flex items-center gap-1 py-1"
                                          >
                                            <span className="text-[#5D97EF]">
                                              Load more
                                            </span>
                                            <span>{group?.length} tasks</span>
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                        <div className="target"></div>
                        {correspondingDetail?.tasks
                          ?.slice(0, tasksToShowByMilestone)
                          .map((tasks) => {
                            return (
                              <div
                                className="check"
                                id={tasks._id}
                                key={tasks._id}
                              >
                                {tasks.status_name !== "DELETE" && (
                                  <TasksListComponent
                                    renderer={renderer}
                                    setRenderer={setRenderer}
                                    data={data}
                                    item={tasks}
                                    taskData={tasks}
                                    setTaskData={setTaskData}
                                    handle="handle"
                                    isAddTaskShown={isAddTaskShown}
                                    setAddTaskIsShown={setAddTaskIsShown}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                      {correspondingDetail?.tasks?.length >
                        tasksToShowByMilestone && (
                        <p
                          onClick={() =>
                            setTasksToShowByMilestone(
                              tasksToShowByMilestone + 5
                            )
                          }
                          className="mt-1 font-bold text-xs pl-1 cursor-pointer flex items-center gap-1"
                        >
                          <span className="text-[#5D97EF]">Load more</span>
                          <span>
                            {" "}
                            {correspondingDetail.tasks.length -
                              tasksToShowByMilestone}{" "}
                            tasks
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

            {milestoneDetails && (
              <MilestoneDetailsModal
                setMilestoneDetails={setMilestoneDetails}
                correspondingMilestone={correspondingMilestone}
              />
            )}

            {showAddTask && (
              <AddTask
                data={data}
                setTaskData={setTaskData}
                isAddTaskShown={showAddTask}
                setAddTaskIsShown={setShowAddTask}
                milestoneId={mileStoneId}
                mileStoneName={mileStoneName}
                setRenderer={setRenderer}
                renderer={renderer}
              />
            )}
            {showGroupModal && (
              <GroupModal
                data={data}
                modal={showGroupModal}
                setModal={setShowGroupModal}
                mileStoneId={mileStoneId}
                renderer={renderer}
                setRenderer={setRenderer}
              />
            )}
          </>
        ) : !showTimeLogFilterData ? (
          <>
            {filteredData?.length > 0 ? (
              filteredData.map((item) => {
                return (
                  <div key={item._id}>
                    <FilterdData
                      item={item}
                      setFilteredData={setFilteredData}
                    />
                  </div>
                );
              })
            ) : (
              <p className="text-xs font-bold">No Result Found</p>
            )}
          </>
        ) : (
          <>
            {timeLogFilteredData?.taskWiseTimeSpent && (
              <div className="flex justify-between items-center px-3 mt-10 pt-3 pb-3 rounded-xl text-sm bg-gray-100 ">
                <p className="font-bold">Filtered Totals:</p>

                <p>
                  <span className="font-bold pr-1">Logged:</span>
                  {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                </p>
                <p>
                  <span className="font-bold pr-1">Non-billable:</span>
                  {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                </p>
                <p>
                  <span className="font-bold pr-1">Billable:</span>
                  {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                </p>
              </div>
            )}

            <div className="relative overflow-x-auto">
              {timeLogFilteredData?.data?.length > 0 &&
                timeLogFilteredData?.data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    {index === 0 ||
                    item.date !== timeLogFilteredData?.data[index - 1]?.date ? (
                      <div className="py-4" key={item.date}>
                        <div className="py-4 font-bold">
                          {moment(item?.date).format("dddd, DD MMMM")}
                        </div>
                        <table className="w-full text-sm text-left rtl:text-right ">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Project
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Who
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Description
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Start
                              </th>
                              <th scope="col" className="px-6 py-3">
                                End
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Billable
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Billed
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Time Spent
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {timeLogFilteredData?.data
                              .filter(
                                (filterItem) => filterItem.date === item.date
                              )
                              .map((filteredItem) => (
                                <TimeLogFilterData
                                  key={filteredItem._id}
                                  data={data}
                                  item={filteredItem}
                                />
                              ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
              {timeLogFilteredData?.data?.length === 0 && (
                <div>
                  <p className="text-xs font-bold">No Result Found</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TasksLists;
