import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select'

export default function FilterByStatus({ data, filteredData, setFilteredData, setShowTaskFilterMenu, showFilterTasks, setShowFilterTasks, filterMenu, setFilterMenu }) {

  const [allMemberData, setAllMemberData] = useState([])
  const [mileStones, setMileStones] = useState([])
  const milestoneRef = useRef(null);
  const assigneeRef = useRef(null);
  const taskStatusRef = useRef(null);
  const taskFinishedRef = useRef(null);

  const usersData = useSelector((state) => state.auth.userInfoData);
  const dispatch = useDispatch()

  useEffect(() => {
    if (data && data.project && data.project._id) {

      const listAllMembersData = async () => {
        const responseListData = await dispatch.people.listAllMembersOnly();
        const getMileStone = await dispatch.projectDetails.getMileStoneList({ project_id: data.project._id })
        setMileStones(getMileStone)
        if (responseListData && responseListData.input) {
          setAllMemberData(responseListData.input)
        }

      }
      listAllMembersData();
    }
  }, [data, filterMenu]);


  const firstOption = usersData ?
    {
      value: usersData._id,
      label: usersData.last_name ? `${usersData.first_name} ${usersData.last_name} (You)` : null
    }
    : null;

  const otherOptions = allMemberData
    .filter(users => users.id !== usersData._id)
    .map(user => ({
      value: user.id,
      label: user.fullName,
    }));

  const options = firstOption ? [firstOption, ...otherOptions] : otherOptions;
  const status_options = [
    { value: 'Todo', label: 'Todo' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Production', label: 'Production' },
    { value: 'QA', label: 'QA' }
  ];

  //submit filter function
  const applyFilter = async () => {
    const expectedState = {
      milestone_id: [],
      milestone_name: [],
      assigned_to: [],
      assigned_to_name: [],
      task_status: [],
      is_task_finished: false,
      task_due_date: ""
    };
    const fieldsAreSame = JSON.stringify(filterMenu) === JSON.stringify(expectedState);


    if (fieldsAreSame) {
      setShowFilterTasks(false)
      setShowTaskFilterMenu(false)
    }
    else {
      const res = await dispatch.projectDetails.getTasksListFilter({
        ...filterMenu
      })
      setShowFilterTasks(true)
      setFilteredData(res.data)
    }

    setShowTaskFilterMenu(false)
  }

  function getFullName(id) {
    const user = allMemberData.find(user => user.id === id);
    return user ? { label: id, value: user.fullName } : '';

  }

  const getMileStoneName = (id) => {
    const milestone = mileStones.find(key => key._id === id);
    return milestone ? { label: id, value: milestone.title } : '';
  }

  const handleClearAll = () => {
    milestoneRef.current.clearValue();
    assigneeRef.current.clearValue();
    taskStatusRef.current.clearValue();
    document.getElementById('taskFinishedRef').checked = false;
    setFilterMenu({
      milestone_id: [],
      milestone_name: [],
      assigned_to: [],
      assigned_to_name: [],
      task_status: [],
      is_task_finished: false,
      task_due_date: ''
    })
    setShowFilterTasks(false)
    // setShowTaskFilterMenu(false)
  }

  return (
    <>
      <div className="flex justify-between">
        <label className="text-xs font-semibold">Milestone</label>
        <button className="text-xs font-bold" onClick={handleClearAll}>Clear Filter</button>
      </div>
      <div className="mt-1 mb-3">
        <Select placeholder="Select Milestone" isMulti ref={milestoneRef}
          className="w-full text-xs"
          onChange={(options) => !options ?
            setFilterMenu({ ...filterMenu, milestone_id: [], milestone_name: [] }) :
            setFilterMenu({
              ...filterMenu,
              milestone_id: options.map(user => {
                return (
                  user.value
                )
              }),
              milestone_name: options.map(user => {
                return (
                  getMileStoneName(user.value)
                )
              }),
            })}
          defaultValue={filterMenu?.milestone_name && filterMenu?.milestone_name.map(item => ({ value: item.label, label: item.value }))}
          isClearable={true}
          options={mileStones.map((item, text) => {
            return {
              value: item._id,
              label: item.title,
            };
          }
          )} />

      </div>

      <div className="">
        <label className="text-xs font-semibold">Assign To</label>
        <div className="mt-1 mb-3">
          <Select placeholder="Select assignee" isMulti ref={assigneeRef}
            className="w-full text-xs"
            defaultValue={filterMenu?.assigned_to_name && filterMenu?.assigned_to_name?.map(item => ({ value: item.label, label: item.value }))}
            options={options}
            onChange={(e) => setFilterMenu({
              ...filterMenu,
              assigned_to: e.map(user => {
                return (
                  user.value
                )
              }),
              assigned_to_name: e.map(user => {
                return (
                  getFullName(user.value)
                )
              }),
            })}

          />
        </div>
      </div>

      <div className="">
        <label className="text-xs font-semibold">Status</label>
        <div className="mt-1 mb-3">
          <Select placeholder="Select Status" isMulti ref={taskStatusRef}
            className="w-full text-xs"
            onChange={(e) => setFilterMenu({
              ...filterMenu,
              task_status: e.map(user => {
                return (
                  user.value
                )
              })
            })}
            defaultValue={filterMenu?.task_status && filterMenu?.task_status?.map(item => ({ value: item, label: item }))}
            options={status_options.map((option, text) => {
              return {
                value: option.value,
                label: option.label,
              };
            }
           
            )} 
            />
        </div>
      </div>


      <div className="flex justify-between space-x-3">

        <div className="w-full">
          <label className="text-xs font-semibold">Due Date</label>
          <div className="border border-[#cccccc] relative rounded-[4px]">

            <div className="flex items-center absolute top-2 ml-3  border-r-2 border-gray-400 pr-5">
              <input id="taskFinishedRef"
                className="text-sm outline-none checkmark"
                type="checkbox"
                defaultChecked={filterMenu.is_task_finished && filterMenu.is_task_finished}
                onChange={(e) => setFilterMenu({ ...filterMenu, is_task_finished: e.target.checked })}
                disabled={filterMenu.task_due_date}
              />
            </div>

            <div className="flex items-center rounded-md ml-16">
              <input
                className="p-3 w-full text-xs outline-none"
                type="date"
                onChange={(e) => setFilterMenu({ ...filterMenu, task_due_date: e.target.value })}
                defaultValue={filterMenu.task_due_date ? filterMenu.task_due_date : null}
                placeholder="Select due date"
                disabled={filterMenu.is_task_finished}
              />
            </div>
          </div>

        </div>

      </div>

      <button onClick={applyFilter} className="mt-3 borderborder-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold text-white py-3 px-5 flex gap-2 items-center">
        Apply
      </button>
    </>
  );
}
