import React from 'react'

export default function 
() {
  return (
    <div className='mb-3'>
        <div className='flex gap-3 mb-2'>
<div className='p-4'></div>


    <div>
        <h4 className='text-tiny font-semibold text-gray-600'>Ralph Edwards</h4>
        <p className='text-xss text-gray-500 mb-3'>30 May 2022</p>
        <p className='text-tiny text-gray-500 mb-1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>

    </div>
</div>
    </div>
  )
}
